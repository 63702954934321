@import 'src/css/globals.scss';

.title {
  color: $AU_blue_shade_0;
}

.controls {
  //
}

.filter_actions {
  position: absolute;
  top: 32px;
  right: 32px;
}

.manage_filters {
  //
}

/* table styles */
.sequence {
  text-align: center;
  padding-right: 0;
}

.filter_type {
  //
}

.validity {
  text-align: right;
}

.name {
  //
}

.validation_status {
  text-align: right;
  padding-right: 46px;
}