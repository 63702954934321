@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$border-color: $AU_blue_gray_1;

.container {
  max-width: 478px;
  padding: 48px 40px;
}

.banner {
  order: 21;
  margin-top: 44px;
}

.display_name {
  padding-left: 8px;
}

.info {
  padding: 14px 0;
  border-color: $border-color;
  border-style: solid;
  border-width: 1px 0 1px 0;
  @include font-size(16px, 27px);
}

.description {
  margin-top: 28px;
}

.confirmation_header {
  // TODO update .body class for component styles in au-core
  + div {
    margin-top: 32px;
    // TODO update .buttons class for component styles in au-core
    + div {
      margin-top: 32px;
    }
  }
}
