@use "sass:math";

@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$glow_color: #E1E026;

@keyframes glow {
  from {
    box-shadow: inset 0px 11px 8px -10px $glow_color,
                inset 0px -11px 8px -10px $glow_color;
  }
  to {
    box-shadow: unset;
  }
}

@keyframes glow_first {
  from {
    box-shadow: inset 0px 11px 8px -10px $glow_color,
                inset 0px -11px 8px -10px $glow_color,
                inset 11px 0px 8px -10px $glow_color;
  }
  to {
    box-shadow: unset;
  }
}

@keyframes glow_last {
  from {
    box-shadow: inset 0px 11px 8px -10px $glow_color,
                inset 0px -11px 8px -10px $glow_color,
                inset -11px 0px 8px -10px $glow_color;
  }
  to {
    box-shadow: unset;
  }
}

@keyframes highlight_even {
  from {
    background-color: $hover;
  }
  to {
    background-color: $AU_gray_shade_3;
  }
}

@keyframes highlight_odd {
  from {
    background-color: $hover;
  }
  to {
    background-color: #FFFFFF;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.policies {
  + .loader {
    margin-top: 56px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.policy {
  &:first-child {
    border-left: none;
    &:before { 
      border-left: none;
    }
  }
  &:before {
    content: "";
    border-left: 1px dashed;
    margin-left: 7px;
  }
  + .policy_group {
    margin-top: 24px;
  }
  
  .policy_group {
    .statement {
      display: flex;
    }
  }
  
  .statement + .statement {
    margin-top: 32px;
  }

  .statement_node {
    display: flex;
  }

  .toggle {
    cursor: pointer;
    padding-right: 16px;
    
    &:before {
      @include au-icon("\e07b");
      font-size: 16px;
    }
    &.expanded:before {
      @include au-icon("\21");
    }
  }

  .statement_toggle {
    padding-left: 16px;
  }

  .statements_added {
    margin-left: 8px;
    padding: 2px 8px;
    border: 1px solid $AU_blue_gray_1;
    border-radius: 4px;
    background: #EEF7FF;
  }

  .entry {
    position: relative;
    line-height: math.div(24px, 14px);
  }
  
  .nested .entry {
    padding-left: 24px;
  }
  
  .label {
    font-weight: 700;
    &:after {
      content: ":";
      padding: 0 5px 0 2px;
    }
  }
}

.no_policies {
  margin-top: 16px;
}

.dimmed {
  opacity: 0.5;
  @include font-size(14px, 20px);
}

.create_policy_btn {
  margin-top: 24px;
}

.load_more {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.load_more_btn {
  margin-top: 8px;
  text-transform: unset !important;
}

.view_history_btn {
  margin-top: 24px;
  text-transform: unset !important;
}

.statements {
  margin-left: 7px;
  border-left: 1px dashed;
}

.statement {
  &.added {
    &:nth-child(even) > div {
      & {
        animation: glow 4s ease,
                   highlight_even 4s ease;
      }
      &:first-child {
        animation: glow_first 4s ease,
                   highlight_even 4s ease;
      }
      &:last-child {
        animation: glow_last 4s ease,
                   highlight_even 4s ease;
      }
    }
    &:nth-child(odd) > div {
      & {
        animation: glow 4s ease,
                   highlight_odd 4s ease;
      }
      &:first-child {
        animation: glow_first 4s ease,
                   highlight_odd 4s ease;
      }
      &:last-child {
        animation: glow_last 4s ease,
                   highlight_odd 4s ease;
      }
    }
  }
  .table {
    padding-left: 0;
  }
}

.row {
  height: 50px;
}

.col {
  border: 1px solid $AU_gray_shade_1;
}

.policy {
  margin-top: -2px;
  flex-grow: 1;
}

.statement_group {
  margin-left: 24px;
  margin-top: -2px;

  &:first-child {
    border-left: none;
    &:before { 
      border-left: none;
    }
  }
  &:before {
    content: "";
    border-left: 1px dashed;
    margin-left: 8px;
  }
  .statement_wrapper {
    padding-left: 24px;
    padding-top: 20px;
    margin-left: 8px;
    border-left: 1px dashed;
    table {
      margin-bottom: 0;
    }
  }  
}

.fieldname {
  composes: col;
  color: $AU_blue_shade_4;
  background-color: $AU_gray_shade_3;
  min-width: 155px;
  vertical-align: top;
  padding: 10px 28px 10px 24px;
  gap: 10px;
}

.fieldvalue {
  composes: col;
  width: 100%;
  padding: 10px 24px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


  .view_json {
    padding-left: 0;
    padding-right: 0;
    font-family: $global-font-base;
    font-weight: normal;
  }

.actions {
  margin-left: 32px;
}

.header {
  div {
    border-color: $AU_gray_shade_0;
  }
}

.cell {
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: normal;
}

.aui {
  min-width: 500px;
}

.condition {
  width: 20%;
}

.entitlements {
  width: 20%;
}

.effect {
  width: 10%;
}

.description {
  width: 15%;
}

.v1 {
  display: flex;
  align-items: center;
  &:before {
    content: "v1";
    width: 24px;
    height: 20px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #FFFFFF;
    background-color: $alert;
    font-weight: 600;
    @include font-size(12px, 14px);
  }
}

.json_viewer_tabs {
  display: flex;
  flex-shrink: 0;
  padding-top: 0;
}

.json_viewer_panel {
  overflow: auto;
  max-height: calc(100vh - #{$global-nav-height} - 134px);
}
