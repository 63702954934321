@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$btn-color: $AU_blue_shade_2;
$btn-bg_color: rgba(#7F198D, .11);
$btn-bg_color-hover: rgba(#FFBDE1, .15);
$btn-border_color: rgba(#7F198D, .11);
$btn-border_color-hover: #7F198D;

$counter-color: #FFFFFF;
$counter-bg_color: #7F198D;

.container {
  /* */
}

.button {
  display: flex;
  outline: none;
  border: 1px solid $btn-border_color;
  border-radius: 24px;
  background-color: $btn-bg_color;
  background-clip: padding-box;
  color: $btn-color;
  @include font-size(14px, 18px);
  font-family: $global-font-sans;
  font-weight: 600;
  cursor: pointer;
  padding: 14px 22px 12px;

  &:hover, &:focus {
    border-color: $btn-border_color-hover;
    background-color: $btn-bg_color-hover;
  }

  &:after {
    display: inline-block;
    content: attr(data-count);
    height: 20px;
    @include font-size(14px, 20px);
    padding: 0px 6px;
    background-color: $counter-bg_color;
    color: $counter-color;
    margin-left: 10px;
  }
}

.counter {
  width: 19px;
  height: 19px;
  position: absolute;
  left: 68px;
  top: 0;
  @include font-size(14px, 19px);
  font-weight: 600;
  color: $counter-color;
  background: $counter-bg_color;
  text-align: center;
  cursor: pointer;
}
