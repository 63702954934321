@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';

.icon {
  &:before {
    margin-right: 8px;
  }
}

.bound {
  composes: icon;
  &:before {
    @include au-icon("\7d");
    color: $primary_btn;
  }
}