.container {
  /* spacer */
  padding-bottom: 100px;
}

.exhibit {
  margin: 10px;
  padding: 10px;
  background-color: #AAAAAA;
}

.title {
  padding: 0 6px;
  margin-bottom: 6px;
  background-color: #CCCCCC;
}

.content {
  background-color: #FFFFFF;
}

.code {
  margin-top: 5px;
  font-family: Monospace;
  background-color: beige;
}

.wrapper {
  background-color: #ccc;
  border: 2px solid cyan;
  position: relative;
}

.flexbox {
  composes: wrapper;
  display: flex;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 10px;

  > p {
    margin: 0;
  }
  > button, > input {
    margin-bottom: 15px;
  }
}

.status_badge_container {
  margin: 13px 0;
  display: flex;
}

.table {
  table-layout: auto;
  position: relative;
  border-collapse: collapse;
}

.draggable > div {
  cursor: grab;
}