@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

.container {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  text-transform: capitalize;
  @include mq($until: tablet) {
    margin-left: unset;
    align-items: center;
    margin-top: 32px;
    text-align: center;
  }
  @include mq(tablet, tablet-ls) {
    margin-left: unset;
    margin-top: 40px;
    align-items: center;
  }
}

.title {
  @include font-size(16px, 22px);
  margin-bottom: 8px;
}

.value {
  @include font-size(28px, 36px);
  margin-bottom: 0;
  font-weight: 600;
  font-family: $global-font-sans;
  text-transform: lowercase;
}