@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.control {
  display: flex;
  + .control {
    margin-top: 8px;
  }
  @include mq(tablet, desktop) {
    margin-top: 8px;
  }
}

.label {
  text-align: right;
  min-width: 43px;
  line-height: 48px;
  @include mq(tablet, desktop) {
    color: $AU_blue_shade_4;
    text-align: right;
    margin-right: 10px;
    margin-left: 23px;
    display: flex;
    justify-content: flex-end;
    min-width: 80px;
  }
}

.input {
  flex: 1;
  margin-left: 16px;
  display: flex;
  width: 100%;
  > div, > input {
    flex: 1 0 auto;
    width: auto !important;
  }
  @include mq(tablet, desktop) {
    margin-left: 0;
    margin-top: 5px;
    margin-right: 24px;
  }
}

.selection > input {
  flex: 1 0 auto;
  width: auto !important;
}

.passthrough_text {
  width: 100%;
  text-align: center;
  color: $AU_blue_shade_2;
  opacity: 0.4;
}

.hint {
  margin-top: 16px;
  @include font-size(14px, 20px);
}

.remove_padding {
  margin-top: unset !important;
  > div {
    @include mq($until: desktop) {
    margin-top: unset !important;
    margin-right: 24px;
    margin-left: unset;
  }
  }
}

.attribute_uri {
  display: none;
}

.annotation_label {
  width: 82px;
  @include mq($from: desktop) {
    width: 85px;
  }
}