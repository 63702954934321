@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$width: 60px;
$height: 90px;
$icon_height: 30px;

.container {
  width: $width;
  height: $height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $AU_gray_shade_3;
  border: 1px solid $AU_blue_gray_1;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $icon_height;
  cursor: pointer;
  color: $AU_blue_shade_0;
  &:hover {
    color: $link;
  }
}

.plus {
  composes: icon;
  &:after {
    @include au-icon("\74");
    font-size: 20px;
  }
}

.minus {
  composes: icon;
  &:after {
    @include au-icon("\e017");
    font-size: 20px;
  }
}
