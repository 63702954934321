@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$input_width_desktop: 560px;
$actions_width: 64px;

.container {
  flex: 1 0 auto;
}

.statement {
  display: flex;
  background-color: #FFF;
  border: 1px solid $AU_blue_gray_1;
  + .statement {
    margin-top: 16px;
  }
}

.v1_icon {
  &:before {
    content: "v1";
    width: 24px;
    height: 20px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #FFFFFF;
    background-color: $alert;
    font-weight: 600;
    @include font-size(12px, 14px);
  }
}

.v1_warning {
  composes: v1_icon;
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 14px 20px;
  background-color: $error;
  line-height: 20px;
}

.fields {
  flex: 1 0 auto;
  padding: 8px 32px 24px;
  width: calc(100% - #{$actions_width});
}

.actions {
  display: flex;
  width: $actions_width;
  justify-content: center;
  border-left: 1px solid $AU_blue_gray_1;
}

.add_bottom {
  display: inline-block;
  @include font-size(16px, 24px);
  margin-top: 16px;
}

.add_top {
  display: inline-block;
  @include font-size(16px, 24px);
}

.delete {
  cursor: pointer;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  user-select: none;
  text-align: center;
  padding: 0;
  margin-top: 9px;

  &, &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: $hover;
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &:after {
    @include au-icon("\69");
  }
}

.deleted {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  background-color: $error;
  @include font-size(16px, 20px);
  height: 48px;
}

.deleted_aui {
  display: flex;
}

.deleted_msg {
  color: $alert;
  margin-right: 24px;
}

.undo {

}

.dropdown {
  margin-bottom: 12px;
  @include mq($until: tablet) {
    width: 100%;
    margin-bottom: 8px;
  }
  @include mq(tablet, desktop) {
    width: 50%;
    padding-right: 16px;
  }
  @include mq($from: desktop) {
    margin-right: -1px;
    width: $input_width_desktop;
  }
}

.selection {
  > input[type="text"] {
    width: 100% !important;
    &:focus {
      z-index: 1;
    }
  }
}

.effect {
  height: 46px;
}

.input > textarea {
  min-height: unset;
  height: 80px;
}
