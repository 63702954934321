@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.fetch_status {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.loading {
  composes: fetch_status;
  &:before {
    display: none;
  }
  > span {
    display: flex;
  }
}

.count {
  font-weight: 600;
  margin: 0 5px;
}

.spinner {
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.button {
  color: $link;
  margin: 0 5px;
  cursor: pointer;
}

.inactive {
  composes: fetch_status;
  display: flex;
  &:before {
    @include au-icon("\e02a");
    color: #4FAAD7;
    position: relative;
    margin-right: 10px;
  }
  > span {
    display: flex;
  }
}

.partial {
  composes: inactive;
  &:before {
    @include au-icon("\6b");
    color: $warning;
  }
}

.error {
  composes: inactive;
  &:before {
    @include au-icon("\27");
    color: $alert;
  }
}

.success {
  composes: fetch_status;
  color: #5C872B;
  &:before {
    @include au-icon("\7d");
    margin-right: 8px;
    color: #5C872B;
  }
  > span {
    display: flex;
  }
}

.hide {
  display: none
}