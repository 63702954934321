@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';

.fragment_top_bar {
  display: flex;
  background-color: $AU_gray_shade_3;
  height: 64px;
  border: 1px solid $AU_blue_gray_1;
  border-bottom: 0;
}

.view_control_container {
  display: flex;
  align-items: center;
}

.search_container {
  /* not supported yet */
  display: none;
  align-items: center;
  margin-left: auto;
}

.extras_container {
  display: flex;
  margin-left: auto;
  padding-right: 32px;
  align-items: center;
}

.icon_container {
  width: 40px;
  height: 40px;
  display: flex;
  background-color: #FFF;
  border: 1px solid $AU_blue_gray_1;
}

.icon {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $AU_blue_shade_0;
  &:after {
    display: block;
    font-size: 16px;
  }
}

.full_topology_icon {
  border-right: none;
  /* rounded left side */
  border-radius: 3px 0 0 3px;
  composes: icon;
  &:after {
    @include au-icon("\e067");
  }
}

.half_topology_icon {
  composes: icon;
  &:after {
    @include au-icon("\e068");
  }
}

.no_topology_icon {
  border-left: none;
  /* rounded right side */
  border-radius: 0 3px 3px 0;
  composes: icon;
  &:after {
    @include au-icon("\e069");
  }
}

.activeTopologyView {
  background-color: $AU_blue_shade_0;
  color: #FFF;
}

.title {
  color: $AU_blue_shade_2;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 23px 10px 23px 32px;
}

.topology_container {
  border: 1px solid $AU_blue_gray_1;
  border-bottom: none;
}

.topology_container_full {
  height: 100vh;
}

.topology_container_half {
  height: 409px;
  border-bottom: 8px solid #CFD5DC;
}

.topology_container_none {
  height: 0px;
}