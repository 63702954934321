@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';


.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 48px 32px 32px;
}

.form_container {
  background-color: $AU_gray_shade_3;
  padding: 32px;
  margin: 32px 0;
}

.inputs_container_inner {
  display: flex;
  margin-bottom: 24px;
}

.inputs_group_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.inputs {
  width: 100%;
}

.inputs_container {
  display: flex;
}

.input {
  margin-right: 16px;
  @include mq($from: desktop) {
    margin-right: 12px;
  }
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.values {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  color: $AU_blue_shade_0;
}

.input_container {
  display: flex;
  align-items: center;
  text-align: right;
}

.input_label_container {
  display: flex;
  flex-direction: column;
}

.message_label {
  color: $AU_blue_shade_4;
  margin-top: 32px;
  @include mq($from: desktop) {
    margin-top: 28px;
  }
}

.text_label {
  color: $AU_blue_shade_4;
  margin-right: 16px;
  margin-top: 16px;
  width: 142px;
  min-width: 142px;
  text-align: right;
  @include mq($from: desktop) {
    margin-top: 12px;
  }
}

.name_label_container {
  color: $AU_blue_shade_4;
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 38px;
  @include mq($from: desktop) {
    margin-top: 28px;
  }
}

.dropdown {
  margin-left: 16px;
}

.name_dropdown {
  composes: dropdown;
  width: 500px;
  margin-top: 16px;
}

.version_dropdown {
  composes: dropdown;
  width: 200px;
  margin-top: 16px;
}

.lookup_button {
  margin-right: 5px;
  padding: 13px 32px;
  border-radius: 4px;
}

.tabs_form_container {
  padding:  40px 32px 32px 32px;
  background: #FFFFFF;
  border-left: 1px solid $AU_blue_gray_1;
  border-right: 1px solid $AU_blue_gray_1;
  border-bottom: 1px solid $AU_blue_gray_1;
}

.tabs {
  margin-top: 48px;
}

.tab {
  text-transform: capitalize !important;
  font-weight: 400;
  color: $AU_blue_shade_0 !important;
}

.rule_dropdown {
  composes: input;
  width: 200px;
}

.selected {
  > div {
    > input::placeholder {
      color: unset !important;
      font-size: 16px !important;
      line-height: 28px !important;
    }
  }
}

.name_input {
  composes: input;
  width: 400px;
}

.group_input {
  composes: input;
  width: 600px;
}

.pou_input {
  width: 200px;
}

.tag_input {
  width: 295px;
}

.add_button {
  width: 71px;
  padding: 0 20px;
}

.optional {
  @include font-size(14px, 20px);
  color: $AU_blue_shade_2;
  opacity: .5;
}

.hint {
  @include font-size(14px, 20px);
  margin-bottom: 16px;
  &:before {
    @include au-icon("\e02a");
    color: $info;
    margin-right: 10px;
  }
}

.application_input_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.empty_table {
  color: $AU_gray_shade_1;
  @include font-size(14px, 20px);
  background-color: $AU_gray_shade_3;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $AU_gray_shade_0;
}

.table_container {
  border: 1px solid $AU_blue_gray_1;
}

.remove_button {
  border: none;
  background: none;
  outline: none;
  width: 54px;
  user-select: none;
  text-align: center;
  padding: 0;
  cursor: pointer;
  &:after {
    @include au-icon("\69");
  }
  &, &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: $hover;
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.remove_column {
  text-align: right;
}

.header_cell {
  text-align: right;
}

.align_left {
  text-align: left;
}

.error_margin {
  margin-top: 54px;
}

.namespace_options {
  width: unset;
}

.permitted_fields_button {
  padding-top: 40px;
}

.hint_container {
  padding-bottom: 16px;
}

.permitted_fields_container {
  background-color: $AU_gray_shade_3;
  padding: 32px;
  margin-bottom: 16px;
}

.namespace_options {
  width: unset;
}