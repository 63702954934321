@import 'src/css/globals.scss';
@import 'src/css/variables.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.metric:before {
  @include au-icon("\e056");
  padding-right: 8px;
  color: $AU_blue_shade_0;
}

.beta:before {
  @include au-icon("\e04b");
  padding-right: 8px;
  color: $beta-tag;
}

.feed_event_link {
  display: inline-block;
  font-size: 14px;

  &:after {
    @include au-icon("\e02b");
    color: $link;
    padding-left: 10px;
    font-size: 12px;
    vertical-align: text-top;
  }
}
