@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  position: relative;
  padding: 0;
  max-width: 100%;
}

.content {
  overflow-y: scroll;
}

.copy {
  position: absolute;
  width: 80px;
  right: 16px;
  top: 18px;
  line-height: 32px;
  text-align: center;
}

button.copy {
  @include font-size(14px, 20px);
  font-weight: 600;
  right: 0;
}

.container_json {
  flex: 1 0 auto;
  background-color: #FFFFFF;
  border-color: $AU_gray_shade_1;
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  padding: 48px 24px;

  pre {
    color: $AU_blue_shade_1;
    max-width: 100%;
    margin: 0;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
}

.key {
  color: #5CAE02;
}

.tabs {
  margin-top: 2px;
}