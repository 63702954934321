@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$input-width: 600px;

$label-color: $AU_blue_shade_4;
$value-color: $AU_blue_shade_2;
$error-color: $alert;

.container {
  width: 100%;

  > .note {
    padding-left: 10px;
  }
}

.list {
  .panel {
    display: flex;
    flex-direction: column;
  }
}

.table {
  .container {
    display: table;
  }
}

.row {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.table .row {
  display: table-row;
  line-height: 48px;
  + .row {
    .control, .label {
      padding-top: 16px;
    }
  }
}

.row_grouped {
  composes: row;
  margin-bottom: -10px;
  + .row:not(.row_grouped) {
    margin-top: 10px;
  }
}

.group {
  flex: 1;
  display: flex;
  flex-grow: 3;
}

.control {
  flex: 3;
  display: flex;
  flex-direction: column;
  position: relative;
  color: $value-color;

  > span {
    padding-left: 1px;
  }
}

.table .control {
  display: table-cell;
  vertical-align: top;
  width: 100%;
  .input, .dropdown {
    @include mq($from: tablet-ls) {
      width: $input-width;
    }
  }
  .number_dropdown {
    @include mq($from: tablet-ls) {
      width: 84px;
    }
  }
}

.label {
  flex: 1;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  white-space: nowrap;
  color: $label-color;
  padding: 16px 0 6px;
}

.table .label {
  text-align: right;
  padding: 0 16px 0 0;
  line-height: 48px;
}

.spacer {
  flex: 1;
  min-width: 120px;
  padding-right: 17px;

  @include mq($until: tablet-ls){
    display: none;
  }
  @include mq($from: tablet-ls) {
    display: flex;
  }
}

.buttons {
  display: flex;

  > button {
    white-space: nowrap;
    @include mq($until: tablet){
      flex: 1 0 auto;
    }
  }

  @include mq($until: tablet){
    flex: 1;
    margin: 24px 32px 32px;
  }
  @include mq($from: tablet){
    margin-top: 24px;
  }
}

.cancel {
  @include mq($until: tablet){
    display: none;
  }
  @include mq($from: tablet){
    padding-left: 0;
    padding-right: 0;
    margin-left: 32px;
  }
}

.error {
  display: flex;
  align-items: center;
  color: $error-color;
  margin-bottom: 10px;
  line-height: 24px;

  > i {
    line-height: 1;
    margin-right: 6px;
  }
}

.note {
  @include font-size(14px, 20px);
  position: relative;
  margin-top: 8px;
  &:before {
    content: "*";
    position: absolute;
    left: 0;
    top: 0;
  }
}

.notice {
  margin-top: 10px;
  @include font-size(14px, 20px);
  &:before {
    @include au-icon("\e02a");
    margin-right: 8px;
    font-size: 16px;
    width: 16px;
    height: 16px;
    color: #4FAAD7;
  }
}

.toggle {
  height: 46px;
}

/**
 * Use to simulate an input for FormattedChildrenInput as that will render
 * a formatter rather than specifically an input
 * TODO: can we pull this out of au_core so we don't get out of sync
 */
.mock_input {
  color: rgba(27, 47, 75, 0.5);
  border: 1px solid #d0d0d0;
  background-color: #f5f5f5;
  width: 560px;
  padding-left: 16px;
  height: 48px;
  position: relative;
}

.label_with_hint {
  line-height: 20px !important;
  @include mq($from: desktop) {
    padding-top: 20px !important;
  }
}

.hint {
  color: $AU_blue_shade_2;
  opacity: .5;
  @include font-size(14px, 20px);
}

.option_hint::after {
  margin-left:3px;
  font-weight: 700;
  @include font-size(14px, 40px);
  content: "(Recommended)";
}

.options_hint {
  margin: 0 24px 24px 24px;
  color: $AU_blue_shade_4;
  @include font-size(14px, 24px);
}

.option_padding {
  padding: 24px;
  display: flex;
  align-items: center;
}

.option_padding_first {
  composes: option_padding;
  margin-top: 12px;
}

.option_hint {
  composes: option_padding;
}

.bold_hint {
  @include font-size(14px, 40px);
}

.light_hint {
  color: $AU_blue_shade_4;
  font-weight: 400;
  @include font-size(14px, 24px);
}

.number_options {
  max-height: unset !important;
  width: 278px;
}

.number_dropdown {
  width: unset;
  > div {
    width: 84px;
  }
}

.number_selection {
  > input[type=text][readonly]:not([disabled]) {
    font-size: 16px;
    width: 84px;
  }
}

.toggle {
  vertical-align: middle !important;
}