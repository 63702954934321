/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include inuit-clearfix();
  @include mq($until: tablet){
    padding: 16px;
  }
  @include mq($from: tablet){
    padding: 24px;
  }
}





/* Size variants.
   ========================================================================== */

.o-wrapper--tiny {
  padding-right: $inuit-global-spacing-unit-tiny;
  padding-left:  $inuit-global-spacing-unit-tiny;
}

.o-wrapper--small {
  padding-right: $inuit-global-spacing-unit-small;
  padding-left:  $inuit-global-spacing-unit-small;
}

.o-wrapper--large {
  padding-right: $inuit-global-spacing-unit-large;
  padding-left:  $inuit-global-spacing-unit-large;
}

.o-wrapper--huge {
  padding-right: $inuit-global-spacing-unit-huge;
  padding-left:  $inuit-global-spacing-unit-huge;
}
