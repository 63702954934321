@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';

.icon {
  &:before {
    margin-right: 8px;
  }
}

.unbind {
  composes: icon;
  &:before {
    @include au-icon("\e030");
    color: $alert;
  }
}

.bind {
  composes: icon;
  &:before {
    @include au-icon("\e033");
    color: $primary_btn;
  }
}

.unknown_event {
  composes: icon;
  &:before {
    @include au-icon("\36");
    color: $alert;
  }
}