@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$table_tooltip_bg-color: #FFFFFF !default;
$table_tooltip_border-color: $AU_blue_shade_3 !default;

.container {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  @include mq(tablet, desktop) {
    margin-left: 20px;
  }
}

.keyvalue_container {
  display: flex;
}

.label {
  @include mq(tablet, desktop) {
    color: $AU_blue_shade_4;
    margin-right: 3px;
    min-width: 95px;
    text-align: right;
  }
}

.extra {
  color: $link;
  white-space: nowrap;
  cursor: pointer;
  display: inline;
  &:before {
    content: "...";
    margin-left: 5px;
    color: $AU_blue_shade_4;
    cursor: auto;
  }
}

.fancy_key_value .container .value {
  white-space: normal;
  word-break: break-word;
  @include mq(tablet, desktop) {
    text-align: left;
    margin-right: 24px;
  }
}

.value_margin {
  margin-left: 10px;
}

.remove_trim {
  white-space: pre;
}

.wrapper {
  position: relative;
  display: inline-block;
  &:hover {
    .inner_wrapper {
      visibility: visible;
      transition: .25s all ease;
      transition-delay: 0s;
    }
  }
}

.inner_wrapper {
  position: absolute;
  top: 100%;
  left: -155px;
  visibility: hidden;
  z-index: 5;
  margin-top: 10px;
  transition: .25s all ease;
  transition-delay: .25s;
  &:hover {
    visibility: visible;
  }
}

.hint {
  opacity: .7;
  font-family: $global-font-base;
  font-weight: normal;
  font-size: 14px;
  &:before {
    @include au-icon("\6b");
    color: #E7A32D;
    margin-right: 5px;
    opacity: unset;
  }
  @include mq(tablet, tablet-ls) {
    margin-left: 92px;
  }
  @include mq(tablet-ls, desktop) {
    margin-left: 95px;
  }
}

.pill {
  display: inline-block;
  margin-right: 6px;
  border: 1px solid #E7E7E7;
  background-color: #ffffff;
  padding: 0 6px;
  border-radius: 4px;
}

.value_label {
  @include font-size(16px, 26px);
  margin-left: 28.5px;
}
