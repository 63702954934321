@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/components/entity_policies.module.scss';

.panel {
  padding-top: 24px;
  > div:first-child {
    background: #FFFFFF;
    padding: 0;
    text-transform: none;
    height: 56px;
    position: sticky;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 2;
    > div:first-child {
      &:before {
        right: unset;
        width: 16px;
        height: 16px;
        margin-right: 16px;
        @include au-icon("\21");
        font-size: 16px;
        color: $AU_blue_shade_1;
      }
    }
    &:after {
      content: none;
    }
  }
  > div:last-child {
    padding-top: 16px;
  }
}

.collapsed {
  max-height: 72px;
  overflow: visible;
  margin-bottom: 0;

  /* title */
  > div:first-child {
    > div:first-child:before {
      @include au-icon("\e07b");
    }
  }
  > div:last-child {
    display: none;
  }
}