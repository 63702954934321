@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$input_width_desktop: 'auto';
$vins_height: 120px;

$input-color: $AU_blue_shade_2;
$vins_bg-color: #FFFFFF;
$vins_border-color: $AU_blue_gray_1;
$error-color: $alert;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input  {
  width: 100%;
  input {
    @include mq($until: tablet) {
      width: 100%;
      margin-bottom: 8px;
    }
    @include mq(tablet, desktop) {
      width: 50%;
      padding-right: 16px;
    }
    @include mq($from: desktop) {
      margin-right: -1px;
      width: $input_width_desktop;
    }
  }
}

.form {
  display: flex;
  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.vins {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  resize: vertical; // NOTE not yet supported by Edge
  overflow: auto;
  min-height: $vins_height;
  margin-top: 8px;
  padding: 15px;
  background-color: $vins_bg-color;
  border: 1px solid $vins_border-color;
  border-radius: 2px;
}

.vin {
  /* */
}

.remove_all_btn {
  margin-left: 4px;
  font-family: $global-font-base;
  font-weight: normal;
  height: 34px;
  min-height: 34px;
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: $error-color;
  padding-left: 20px;
  margin-top: 10px;
  &:before {
    @include au-icon("\27");
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
  }
}

.note {
  position: relative;
  padding-left: 10px;
  @include font-size(14px, 20px);
  margin-top: 6px;
  &:before {
    content: "*";
    position: absolute;
    left: 0;
  }
  @include mq(tablet, desktop) {
    text-align: left;
    margin-left: 20px;
  }
}

.hint {
  composes: error;
  color: unset;
  &:before {
    @include au-icon("\e02a");
    color: #1C96CF;
  }
}

.count {
  font-weight: 600;
}

.hint_container {
  font-size: 14px;
  line-height: 20px;
  &:before {
    @include au-icon("\e02a");
    color: #1C96CF;
    margin-right: 5px;
  }
}

.error_container {
  composes: hint_container;
  color: $error-color;
  &:before {
    @include au-icon("\27");
    color: $error-color;
  }
}