@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$min_width: 680px;
$max_height: calc(100vh / 2 - 64px);

$container_bg-color: #FFFFFF;

.container {
  display: flex;
  flex-direction: column;
  background: $container_bg-color;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  width: $min_width;
  padding: 40px 32px;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  @include font-size(20px, 28px);
  font-weight: 600;
  font-family: $global-font-sans;
}

.copy {
  margin-left: auto;
  padding: 0 26px;
  font-weight: 600;
  @include font-size(14px, 19px);
}

.content {
  flex: 1;
  display: flex;
  padding-right: 10px;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: $max_height;
  margin: 24px 0;
  /* space reserved to AuScrollbar */
  padding-right: 24px;

  /* this is a workaround for a weird bug with wrong scrollbar position detection */
  :global(.ps__rail-y) {
    left: auto !important;
  }
}

.record {
  padding-left: 1px;
  border-top: 1px solid $AU_blue_gray_1;
}

.record_title {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.icon {
  &::before {
    margin-right: 8px;
  }
}

.hint {
  opacity: .65;
}

.processing {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  line-height: 18px;
}

.failure {
  composes: icon;

  &, & + .message {
    color: $alert;
  }

  &::before {
    @include au-icon("\36");
    font-size: 16px;
  }
}

.success {
  composes: icon;
  &::before {
    @include au-icon("\2a");
    color: $AU_blue_shade_2;
    font-size: 18px;
  }
}

.aborted {
  composes: icon;
  &::before {
    @include au-icon("\37");
  }
}

.entity {
  @include font-size(16px, 18px);
}

.entity_name {
  composes: entity;
  font-weight: 600;
  &:after {
    font-weight: 400;
    content: " - ";
  }
}

.details {
  padding: 16px 0;
}

.wrapper {
  flex: 1;
  display: flex;
  position: relative;
  + .wrapper {
    margin-top: 24px;
  }
}

.row {
  display: table;
  width: 100%;
}

.alert {
  color: $alert;
  background-color: $error;
  padding: 10px 10px 10px 22px;
  margin: 0 0 20px;
  @include font-size(14px, 20px);
  &::before {
    @include au-icon("\36");
    font-size: 14px;
    padding-right: 8px;
  }
}

.attributes {
  display: table;
  padding-left: 28px;
}

.attribute {
  @include font-size(16px, 32px);
  display: table-row;
}

.attr_name {
  display: table-cell;
  text-align: right;
  color: $AU_blue_shade_4;
  min-width: 60px;
  &:after {
    content: ":";
    padding: 0 6px;
  }
}

.attr_value {
  display: table-cell;
}

.delete {
  position: absolute;
  right: 0;
  top: -10px;
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  user-select: none;
  text-align: center;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  &:after {
    @include au-icon("\69");
  }
}