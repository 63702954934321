@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'src/css/tools/tools.no-user-select';
@import 'node_modules/sass-mq/mq';

@import '../variables.scss';

$invalid_list_item_color: $AU_blue_shade_3;
$invalid_list_item_background_color: #FFFFFF;

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 170px;
  overflow: auto;
  h3 {
    font-family: $global-font-sans;
    line-height: 1.5;
    color: $selection_summary_section_header-color;
    padding: 3px 0 3px;
    margin: 0;
  }
  > div {
    padding: 0 3px;
    flex: 1 1 auto;
  }
  @include mq($until: desktop){
    @include mq($until: tablet){
      padding-bottom: 10px;
    }
    h3 {
      font-weight: normal;
      @include mq($until: tablet){
        padding: 3px 10px;
      }
    }
    > div {
      display: inline-block;
      width: calc(100% * (1/2) - 0px); /* Way to specify number of columns in flex */
      @include mq($until: tablet){
        width: 100%;
      }
    }
  }
}

.list_item {
  display: flex;
  line-height: 1.5;
  padding: 1px 0;
  cursor: pointer;
}

.invalid {
  composes: list_item;
  > .icon {
    display: flex;
    align-items: center;
    &:before {
      @include au-icon("\65");
      @include mq($until: desktop){
        display: inline-flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        background: $invalid_list_item_background_color;
        margin-right: 5px;
      }
      @include mq($from: desktop){
        font-size: 10px;
        margin-right: 8px;
        color: $invalid_list_item_color;
      }
    }
  }
  > .label {
    @include mq($until: desktop){
      display: inline-flex;
      align-items: center;
      background: $invalid_list_item_background_color;
      min-height: 40px;
      padding: 0 10px;
      width: calc(100% - 45px); /* Remainder minus the checkbox and margin */
    }
  }
}

.icon {
  /* */
}

.label {
  /* */
}
