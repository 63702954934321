@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.section {
    margin-top: 36px;
    margin-bottom: 32px;
}

.separator {
    display: flex;
    margin: 0 16px;
    width: 1px;
    height: 12px;
    background-color: $AU_blue_shade_3;
    display: inline-block;
}

.time_header {
    @include font-size(16px, 22px);
    color: #1B2F4B;
    font-weight: 600;
    margin-left: 24px;
}

.rule_section {
    border-bottom: 1px solid #CFD5DC;
    padding: 32px 0;
}

.expanded {
    border-bottom: none;
    padding-bottom: 0;
}

.action_buttons {
    padding: 0;
    @include font-size(16px, 28px);
    display: inline-block;
    color: #1188AA;
    cursor: pointer;
}

.actions {
    padding-bottom: 16px;
    border-bottom: 1px solid #CFD5DC;
}

.empty_message {
    text-align: center;
    padding: 32px;
    @include font-size(14px, 20px);
    color: #D0D0D0;
}