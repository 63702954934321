@import 'src/css/globals.scss';

.container {
  margin: 0 -20px;
}

.table {
  border-collapse: collapse;
  border: 0;
}

.row .cell {
  padding-top: 12px;
  padding-bottom: 12px;
}

.row {
  &:nth-child(even) {
    .cell {
      background-color: $AU_gray_shade_3;
    }
  }
}

.header .cell {
  border-bottom: 1px solid $AU_gray_shade_0;
  background-color: #E7EAED;
}

.cell {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.short {
  width: 75%;
}

.medium {
  width: 100%;
}

.long {
  width: 125%;
}
