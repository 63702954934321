@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';

.container {

  &:before {
    @include au-icon("\5c");
  }
}

.new {
  &:before {
    @include au-icon("\7d");
  }
}

.message {
  margin-bottom: 18px;
}

.rows {
  margin: 0;
  border: 1px solid $AU_gray_shade_1;
}

.row {
  height: 37px;
}

.column {
  padding: 10px 20px;
  border: 1px solid $AU_gray_shade_1;
  line-height: 29px;
}

.fieldname {
  composes: column;
  white-space: nowrap;
  color: $AU_blue_shade_4;
  background-color: $AU_gray_shade_3;
}

.fieldvalue {
  composes: column;
  width: 100%;
  border-right-color: transparent;
}

.fieldcopy {
  composes: column;
  border-left-color: transparent;
  vertical-align: middle;
}
