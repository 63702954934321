@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import './state_icon.module.scss';


.dcs_container {
  display: inline-block;
  padding: 4px 10px;
  color: white;
}

.dcs_icon {
  composes: icon;
  @include font-size(10px, 16px);
  &:before {
    float: left; // pull out of layout
  }
}

.deep_sleep {
  composes: dcs_icon;
  &:before {
    @include au-icon("\e059");
    color: #6F6F6F;
  }
}

.deep_sleep_container {
  composes: dcs_container;
  background-color: $AU_gray_shade_0;
}


.connected {
  composes: dcs_icon;
  &:before {
    @include au-icon("\e057");
    color: #3B5D13;
  }
}

.connected_container {
  composes: dcs_container;
  background-color: #5C872B;
}

.disconnected {
  composes: dcs_icon;
  &:before {
    @include au-icon("\e058");
    color: $AU_blue_shade_0;
     /* dirty trick to fill the status of a round icon in white */
    background-image: $global-round-icon-background-white;
  }
}

.disconnected_container {
  composes: dcs_container;
  background-color: $alert;
}
