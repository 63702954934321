@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$valid_color: #5C872B;
$invalid_color: $alert;
$warning_color: #E49816;

.icon {
  @include font-size(16px, 27px);
  font-family: $global-font-base;
  font-weight: normal;
  text-transform: unset;
}

.valid {
  composes: icon;
  color: $valid_color
}

.invalid {
  composes: icon;
  color: $invalid_color;
}

.warning {
  composes: icon;
  &, span:before {
    color: $warning_color;
  }
}