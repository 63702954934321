@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  padding: 48px 32px 32px;
  width: 100%;
}

.headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.search_result_container {
  display: flex;
  margin: 16px 0 16px;
  padding: 24px 32px;
  background-color: #FAF8F0;
  color: $AU_blue_shade_2;
  @include font-size(16px, 28px);
}

.top_margin {
 margin-top: 52px;
}

.search_container {
  position: relative;
}

.timezone_selector {
  margin-bottom: 10px;
}

.keys {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.values {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.key {
  color: $AU_blue_shade_4;
  margin-right: 10px;
  &:after {
    content: ':'
  }
}

.name_key {
  composes: key;
  min-width: 190px;
}

.namespace_key {
  composes: key;
  min-width: 217px;
}

.text {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #5C872B;
  margin-top: 16px;
  position: absolute;
  right: 0;
  &:before {
    @include au-icon("\7d");
    margin-right: 8px;
    color: #5C872B;
  }
  > span {
    display: flex;
  }
}

.count {
  font-weight: 600;
  margin: 0 5px;
}

.table_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}