///* ========================================================================
//   #AU-ICON
//   ======================================================================== */

// Generate an icon using au-font.
@mixin au-icon($content) {
  font-family: $global-font-au !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $content;
}
