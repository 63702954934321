@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$popout-width: 1015px; /* Currently the width of the popout */

$highlight-output-color: #ffcd5d;
$highlight-input-color: #92af5a;
$action-right-offset: 32px;

.container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: auto;
  position: relative;
  flex: 1;
  height: 100%;

  > svg {
    cursor: grab;
    flex: 1;
  }

  :global(marker#arrowhead-highlight-output path) {
    fill: $highlight-output-color;
  }

  :global(marker#arrowhead-highlight-input path) {
    fill: $highlight-input-color;
  }
}

.search {
  position: absolute;
  right: $action-right-offset;
  top: 8px;
}

.search ~ {
  .zoom {
    top: 78px;
  }

  .tooltip_legend {
    top: 178px;
  }

  .export_svg {
    top: 256px;
  }

  .re_center {
    top: 334px;
  }
}

.control {
  position: absolute;
  right: $action-right-offset;
  width: 60px;

  &:before {
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    content: attr(data-caption);
    font-family: $global-font-sans;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    color: #324661;
  }
}

.button {
  composes: control;
  height: 68px;
  background-color: $AU_gray_shade_3;
  border: 1px solid $AU_blue_gray_1;
  cursor: pointer;
  outline: none;
  padding-top: 16px;

  &:hover:after {
    color: $link!important;
  }
}

.zoom {
  composes: button;
  top: 32px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltip_legend {
  composes: button;
  top: 132px;
}

.export_svg {
  composes: button;
  top: 210px;
  &:after {
    @include au-icon("\e021");
    font-size: 16px;
    color: $AU_blue_shade_0;
  }
}

.re_center {
  display: none; // hidden for now
  composes: button;
  top: 288px;
  &:after {
    @include au-icon("\e06a");
    font-size: 16px;
    color: $AU_blue_shade_0;
  }
}

.popout_controls {
  position: absolute;
  right: $popout-width;
  width: calc(100vw - #{$popout-width});
}

.nodata {
  margin: 30px auto;
  @include font-size(22px, 30px);
  font-family: $global-font-sans;
  color: $AU_blue_shade_2;
  font-weight: 600;
}

path.highlight_output {
  stroke: $highlight-output-color;
  marker-end: url('#arrowhead-highlight-output');
}

path.highlight_input {
  stroke: $highlight-input-color;
  marker-end: url('#arrowhead-highlight-input');
}

.dim {
  opacity: 0.2;
}

.highlight_input, .highlight_output {
  opacity: 1;
}

.selected_halo {
  stroke: $highlight-output-color;
  stroke-width: 5px;
}

.topology_legend_container {
  overflow: hidden;
}

.header {
  position: absolute;
  top: 32px;
  left: 56px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}