@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$container_hover_bg-color: $hover;
$container_hover_border-color: $AU_blue_shade_4;
$icon_default-color: $secondary_btn;
$icon_active-color: $AU_blue_shade_2;
$controller_bg-color: #FFFFFF;
$controller_border-color: $AU_blue_shade_4;

.container {
  border: 1px solid transparent;
  padding: 0 11px;
  position: relative;
  display: flex;
  height: 48px;
  align-items: center;

  &:hover {
    > div {
        > span {
            &:after {
                color: $AU_blue_shade_0;
            }
        }
    }
  }

  @include mq($until: tablet-ls) {
    &.active {
      background-color: $container_hover_bg-color;
      border: 1px solid $container_hover_border-color;

      .caret:after {
        color: $icon_active-color;
      }
      .controller {
        display: flex;
      }
    }
  }

  @include mq($from: tablet-ls) {
    &:hover,
    &.dragging {
      background-color: $container_hover_bg-color;
      border: 1px solid $container_hover_border-color;

      .caret:after {
        color: $icon_active-color;
      }
      .controller {
        display: flex;
      }
    }
  }
}

.action_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  &:before {
    @include au-icon("\e086");
    background-color: $AU_blue_shade_0;
    color: #ffffff;
    font-size: 14px;
  }

  &_caret {
    margin-left: 4px;

    &::after {
      @include au-icon("\45");
      color: $icon_default-color;
      font-size: 14px;
    }
  }

  &_label {
    margin: 0 6px;
  }
}

.controller {
  display: none;
  flex-direction: column;
  position: absolute;
  min-width: 244px;
  max-height: 364px;
  overflow: auto;
  left: -1px;
  z-index: 9;
  background-color: $controller_bg-color;
  border: 1px solid $controller_border-color;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  top: 46px;

  .available_fields {
    height: 0px;
    visibility: hidden;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;

    .field_item {
      height: 0px;
      visibility: hidden;
    }
  }

  .selected_fields {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-top: 1px solid $AU_blue_gray_1;
  }
}

.collapsible {
  &_header {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  &_title {
    margin-left: 8px;
  }

  &_number {
    font-size: 16px;
    color: $AU_blue_shade_2;
    padding: 0 8px;
    background-color: $AU_gray_shade_3;
    line-height: 27px;
  }

  &_icon {
    position: absolute;
    right: 20px;

    &::after {
      @include au-icon("\45");
      color: $AU_blue_shade_0;
    }

    &.expanded_icon::after {
      @include au-icon("\56");
    }

    &.disabled_icon::after {
      color: #D0D0D0;
      cursor: auto;
    }
  }

  &.expanded {
    .available_fields {
      height: auto;
      visibility: visible;
      padding-bottom: 10px;

      .field_item {
        visibility: visible;
        height: 36px;
      }
    }
  }
}

.field_item {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  background-color: #FFFFFF;
  cursor: grab;

  &:hover {
    background-color: #faf8f0;
  }

  &_text {
    text-transform: capitalize;
    user-select: none;
    text-wrap: nowrap;
    margin-right: 40px;
  }
}

.field_icon {
  position: absolute;
  right: 22px;
  cursor: pointer;
}

.add {
  composes: field_icon;
  &::after {
    @include au-icon("\74");
  }
}

.remove {
  composes: field_icon;
  &::after {
    @include au-icon("\69");
    color: $AU_blue_shade_0;
    font-size: 12px;
    margin-left: 4px;
  }
}

.draggable {
  margin-right: 20px;
  margin-bottom: 2px;
  &:before {
    @include au-icon("\e08d");
    font-size: 13px;
  }
}

.disabled {
  cursor: auto;
}