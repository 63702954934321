@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  display: flex;
  flex-direction: column;
  margin: 48px auto 0;
  height: calc(100vh - 80px); // so the SVG doesn't go behind footer when scrolling
}

.landing_page {
   composes: container;
   margin: 0 auto;
   height: unset;
}

.sample {
  margin: 0 auto 0;
  display: block;
  // tweak minimum width and height so centering works
  width: 1172px;
  height: 100vh;
}

.title {
  margin: 0 auto;
  @include font-size(22px, 30px);
  font-family: $global-font-sans;
  color: $AU_blue_shade_2;
  font-weight: 600;
}

.loading {
  @include mq($until: desktop) {
    display: none;
  }
  margin: 0 auto 52px auto;
  height: 103px;
  width: 649px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $AU_gray_shade_3;
}

.spinner {
  height: 42px;
  width: 42px;
  margin: 0 0 0 auto;
}

.rendering {
  margin: 0 auto 0 21px;
  @include font-size(16px, 22px);
  font-family: $global-font-sans;
  color: $AU_blue_shade_2;
  font-weight: 600;
}

.hat {
  display: flex;
  flex-direction: column;
}
