@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$add_btn_tablet_bg-color: $AU_gray_shade_3;

.container {
  flex-direction: column;
  display: flex;
}

.inputs {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}

.property_row {
  display: flex;
  flex-direction: row;
}

.dropdown {
  margin-right: 16px;
  @include mq($from: desktop) {
    margin-right: 10px;
  }
}

.dropdown, .input {
  width: 50%;
  @include mq($from: desktop) {
    width: 308px;
  }
}

.add {
  border: unset;
  background: unset;
  display: flex;
  color: $link;
  cursor: pointer;
  width: 120px;
  @include font-size(16px, 24px);
  height: 20px;
  @include mq($until: tablet) {
    width: 130px;
  }
}

.add:hover, .remove:hover, .add:focus {
  color: $link;
}

.remove {
  composes: add;
  margin-left: 10px;
  align-items: center;
  width: unset;
  height: auto;
}

.error {
  font-size: 14px;
  color: #A22330;
  width: 100%;

  &:before {
    @include au-icon("\27");
    font-size: 14px;
    color: #A22330;
    margin-right: 10px;
  }
}

.dropdown_error {
  composes: dropdown;
  background-color: #fff3f3;
  border-color: #a22330;
}