@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.header {
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    top: 8px;
    @include au-icon("\59");
    width: 16px;
    height: 18px;
    font-size: 18px;
  }
  &:after {
    position: absolute;
    top: 2px;
    left: 5px;
    @include au-icon("\5d");
    background-color: #FFFFFF;
    width: 16px;
    height: 18px;
    font-size: 18px;
  }
}

.section {
  margin-top: 24px;
}

.caption {
  color: $AU_blue_shade_4;
  @include font-size(16px, 32px);
}

.value {
  color: $AU_blue_shade_2;
}

.current {
  composes: caption;
}

.destination {
  composes: caption;
}

.dropdown {
  margin-top: 4px;
  width: 500px;
}

.notice {
  margin-top: 24px;
  @include font-size(14px, 20px);
  &:before {
    @include au-icon("\e02a");
    margin-right: 8px;
    font-size: 16px;
    width: 16px;
    height: 16px;
    color: #4FAAD7;
  }
}
