@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

.container {
  display: flex;
  align-items: center;
  padding-left: 80px;
  width: 530px;
  background: #FFFFFF;
  border: 1px solid $AU_blue_gray_1;
  @include mq($until: tablet) {
    flex-direction: column;
    max-width: 283px;
    padding-left: unset;
    padding: 40px;
  }
  @include mq(tablet, tablet-ls) {
    height: 333px;
    width: 357px;
    flex-direction: column;
    padding-left: unset;
    justify-content: center;
  }
  @include mq($from: tablet-ls) {
    height: 160px;
  }
}

.valid, .bound, .connected, .received_user_input_allow, .device_response_received, .success {
  background: #F3FCF3;
  border: 1px solid #5B8037;
}

.not_bound, .invalid, .disconnected, .received_user_input_deny, .cancelled, .failure  {
  background: #FFF3F3;
  border: 1px solid $alert;
}

.in_flight, .running, .expired {
  background: #F5FCFF;
  border: 1px solid #4FAAD7
}

.deep_sleep {
  background: #FFF7E7;
  border: 1px solid #E49816;
}

.expired {
  background: $AU_gray_shade_3;
  border: 1px solid #6F6F6F;
}