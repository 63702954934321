@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px 16px 0px 16px;
  width: 100%;
}

.labelled_value {
  display: flex;
  margin: 25px 32px 0px;
  flex-direction: column;
  align-items: center;
  border-width: 1px;
  border-radius: 2px;
  border-color: #2d3f56;
}

.value_input {
  margin-bottom: 25px;
  width: 100%;
}

.value_text{
  align-self: start;
  margin: 20px 0;
  @include font-size(14px, 20px);
  &:before{
    @include au-icon("\e02A");
    margin-right: 10px;
    color: $global-badge-running
  }
}

.value_label {
  align-self: start;
  width: 100%;
  padding: 8px 20px 8px 20px;
  margin-bottom: 0px;
  border-width: 1px;
  border-radius: 0px;
  border-color: #425671;
  background-color: #E5E5E5;
}
