@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  display: flex;
  flex-direction: column;
}

.inputs {
  display: flex;
  flex-direction: row;
}

.input {
  width: 100px;
  margin-right: 15px;
}

.error {
  display: block;
  color: $alert;
  margin-top: 7px;
  @include font-size(14px, 20px);

  &:before {
    @include au-icon("\36");
    width: 14px;
    height: 14px;
    font-size: 12px;
    margin: 0px 6px 0 2px;
  }
}