@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

// NOTE keep in sync with fieldset.scss [$input-width]
$max-width: 627px;
$error-color: $alert;

.container {
  width: $max-width;
  display: flex;
  flex-direction: column;
}

.chips {
  > div { 
    resize: vertical;
    overflow: auto;
    height: unset;
    max-height: unset;
    padding-top: 6px;
    padding-left: 16px;
    height: 48px;
    min-height: 48px;
    > div, > div:last-child {
      margin-bottom: 8px;
    }
    > div[data-for] {
      border-radius: 4px;
      padding-left: 8px;
      padding-right: 4px;
      margin-right: 8px;
    }
  }
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: $error-color;
  padding-left: 20px;
  margin-top: 10px;
  &:before {
    @include au-icon("\27");
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
  }
}

.note {
  position: relative;
  padding-left: 10px;
  @include font-size(14px, 20px);
  color: rgba(50, 70, 97, 0.7);
  margin-top: 6px;
  &:before {
    content: "*";
    position: absolute;
    left: 0;
  }
}
