@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$input_width_desktop: 295px;
$btn_min_width: 71px;
$tags_height: 120px;

$input-color: $AU_blue_shade_2;
$tags_bg-color: #FFFFFF;
$tags_border-color: $AU_blue_gray_1;

.container {
  display: flex;
  flex-direction: column;
}

.dropdown {
  margin-right: 16px;
  @include mq($until: tablet) {
    width: 100%;
    margin-bottom: 8px;
  }
  @include mq(tablet, desktop) {
    width: 50%;
  }
  @include mq($from: desktop) {
    width: $input_width_desktop;
    margin-right: 10px;
  }
}

.hideToggle {
  display: none;
}

.selection {
  > input[type="text"] {
    width: 100% !important;
    &:focus {
      z-index: 1;
    }
  }
}

.add_btn {
  padding: 0 20px;
  @include mq($until: tablet) {
    width: 100%;
  }
  @include mq($from: tablet) {
    min-width: $btn_min_width;
  }
  text-transform: capitalize;
}

.form {
  display: flex;
  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  resize: vertical; // NOTE not yet supported by Edge
  overflow: auto;
  min-height: $tags_height;
  margin-top: 8px;
  padding: 15px;
  background-color: $tags_bg-color;
  border: 1px solid $tags_border-color;
  border-radius: 2px;
}

.tag {
  /* */
}

.remove_all_btn {
  margin-left: 4px;
  font-family: $global-font-base;
  font-weight: normal;
  height: 34px;
  min-height: 34px;
  padding-right: unset;
  @include font-size(14px, 20px);
}

.hint {
  font-family: $global-font-base;
  font-weight: normal;
  @include font-size(14px, 20px);
  color: $AU_blue_shade_2;
  opacity: 0.7;
  margin-top: 8px;
}

.extras {
  display: flex;
  justify-content: space-between;
}

.no_hint {
  justify-content: flex-end;
}