@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$info_bg-color: #EEF7FF;
$info_border-color: #1C96CF;
$search_highlight_bg-color: #ffd54f;
$panel-header-height: 72px;

.container {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  & > * {
    height: calc(100vh - 235px);
  }
}

.container + div { /* filter side bar */
  z-index: 3;
}

.loader {
  display: flex;
  align-items: center;
  margin: 64px 0;
  height: 32px;
}

.content_wrapper {
  width: 100%;
}

.content {
  flex: 1 1 auto;
  overflow-y: scroll;
  height: calc(100vh - 268px);
}

.no_data {
  border-top: 1px solid #E7E7E7;
  overflow-y: unset;
  display: flex
}

.no_data_message {
  margin: 32px auto;
}

.version_container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  white-space: nowrap;
  cursor: default;
}

.version_label {
  width: 100px;
  color: rgba(50, 70, 97, 0.7);
}

.draft_label {
  color: #B57B05;
}

.panel {
  position: relative;
  /* title */
  > div:first-child {
    background-color: white;
    border-top: 1px solid #E7E7E7;
    text-transform: unset;
    height: 72px;
    display: flex;
    font-family: 'Arimo';
    font-weight: 400;
    font-size: 16px;
    color: #324661;
    line-height: 27px;
    position: sticky;
    top: 0;
    padding-left: 0;
    > div:first-child {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      &:before {
        right: unset;
        width: 16px;
        height: 3px;
        margin-right: 16px;
        @include au-icon("\e017");
        font-size: 16px;
        color: $AU_blue_shade_1;
      }
    }
    &:after {
      content: none;
    }
  }
  > div:nth-child(2) { /* panel content */
    > div { /* dynamic data table container */
      > div { /* dynamic data table */
        > div:first-child {
          position: sticky;
          top: $panel-header-height;
          z-index: 2;
          background-color: white;
        }
        > div:nth-child(2) { /* table content */
          height: auto;
          contain: unset;
          overflow: visible !important;
          z-index: 1;
        }
      }
    }
  }
}

.collapsed {
  margin-bottom: 0;
  max-height: 72px;
  overflow: visible;
  cursor: default;
  /* title */
  > div:first-child {
    > div:first-child:before {
      @include au-icon("\74");
    }
    > div:first-child:after {
      transform: unset;
    }
  }
  > div:last-child {
    display: none;
  }
}

.highlight {
  background-color: $search_highlight_bg-color;
}

.go_to_commands_link {
  position: relative;
}