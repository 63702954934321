@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$icon_size: 16px;
$icon_line-height: 24px;
$icon_text-color: #FFFFFF;
$icon_disabled-color: $alert;
$icon_enabled-color: $primary_btn;
$icon_stopped-color: $alert;
$icon_paused-color: #000000;
$icon_running-color: #1A678C;
$icon_replaying-color: #B67B02;
$valid_color: #5C872B;
$invalid_color: $alert;

.container {
  font-weight: 600;
  @include font-size(10px, 13px);
  text-transform: uppercase;
}

.command_state_container {
  display: inline-block;
  height: $icon_size;
  line-height: $icon_line-height;
}

.icon {
  &:before {
    width: $icon_size;
    height: $icon_size;
    color: $icon_text-color;
    font-size: $icon_size;
    margin-right: 8px;
     /* dirty trick to fill the status icon in white */
    background-image: $global-icon-background-white;
  }
}

.enabled, .disabled {
  &:before {
    font-size: unset;
    margin-right: 8px;
    @include au-icon("\7d");
    color: $icon_enabled-color;
  }
}

.container_enabled, .container_disabled {
  display: inline-block;
  font-weight: unset;
  font-size: unset;
  text-transform: capitalize;
}

.running {
  composes: icon;
  &:before {
    @include au-icon("\e052");
    color: $icon_running-color;
  }
}

.disabled {
  composes: icon;
  &:before {
    @include au-icon("\66");
    color: $icon_disabled-color;
    background-image: unset;
  }
}

.paused {
  composes: icon;
  &:before {
    @include au-icon("\e02e");
    color: $icon_paused-color;
  }
}

.command_definition_draft,
.replay {
  composes: icon;
  &:before {
    @include au-icon("\e050");
    color: $icon_replaying-color;
  }
}

.command_definition_enabled {
  composes: icon;
  &:before {
    @include au-icon("\7d");
    color: darkgreen;
  }
}

.command_definition_valid {
  composes: icon;
  &:before {
    @include au-icon("\7d");
    color: $global-badge-success-green;
  }
}

.command_definition_invalid {
  white-space: nowrap;
  composes: icon;
  &:before {
    @include au-icon("\27");
    color: $alert;
  }
}

.invalid {
  white-space: nowrap;
  composes: icon;
  &:before {
    @include au-icon("\27");
    color: $alert;
  }
}

.failure, .received_user_input_deny, .deny {
  composes: invalid;
  &:before {
    color: $global-stopped-icon;
  }
}

.warning, .incomplete,  .partial_success, .partial_failure {
  composes: icon;
  &:before {
    @include au-icon("\37");
    color: $warning;
  }
}

.stopped {
  composes: failure;
  &:before {
    @include au-icon("\e02d");
  }
}

.in_progress, .in_flight {
  composes: icon;
  &:before {
    @include au-icon("\e034");
    color: $global-running-icon;
  }
}

.success, .received_user_input_allow, .device_response_received, .valid, .permit {
  composes: running;
  &:before {
    color: #3B5D13;
  }
}

.expired {
  composes: stopped;
  &:before {
    color: #6F6F6F;
  }
}

.cancelled {
  composes: icon;
  &:before {
    @include au-icon("\66");
    color: #000000;
  }
}

.starting {
  composes: icon;
  &:before {
    @include au-icon("\e050");
    color: $global-starting-icon;
  }
}

.unknown {
  composes: icon;
  &:before {
    @include au-icon("\e051");
    color: $AU_gray_shade_1;
  }
}

.complete {
  composes: icon;
  &:before {
    @include au-icon("\23");
    color: $global-badge-success-green;
  }
}

.command_definition_disabled {
  composes: stopped;
  &:before {
    color: #6F6F6F;
  }
}
