@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  & > div {
    border-top: none;
  }
  padding-left: 16px;
}

.showAll {
  position: relative;
  margin: 0 16px;
  border: none;
  border-bottom: 1px solid $AU_blue_gray_1;
  padding: 30px 72px 30px 32px;
  font-family: $global-font-base;
  cursor: pointer;
  color: $AU_blue_shade_2;
  background-color: transparent;

  &.checked:before {
    position: absolute;
    top: 32px;
    left: 0;
    width: 16px;
    height: 13px;
    @include au-icon("\23");
    @include font-size(13px, 40px);
    line-height: initial;
  }
}

.subheader {
  margin: 30px 0 0 16px;
  color: $AU_blue_shade_4;
}
