@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$default_row_height: 50px;
$error: #FFF2F2;

.container {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @include mq($until: tablet) {
    padding-bottom: 10px;
  }
  @include mq($from: tablet) {
    /* not margin, since it may cut out some of the page content e.g. actions */
    padding: 0 32px 10px;

  }

  :global .o-wrapper {
    order: 30;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.table_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.subview_open {
  @include mq($until: desktop) {
    margin-left: 230px;
  }
}

.subview_closed {
  @include mq($until: desktop) {
    margin-left: 75px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px 0;
}

.breadcrumbs {
  order: 10;
  line-height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
}


.table_topper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.table_filter_container {
  display: flex;
  height: 100%;
}

.header {
  display: flex;
  position: relative;
  padding-top: 36px;
  margin-bottom: 32px;

  @include mq($until: desktop){
    margin: 24px 25px 18px 32px;
  }
  @include mq($from: desktop){
    height: 84px;
  }

  > .buttons {
    width: 100%;
  }
}

.banner {
  + .sub_header {
    margin-top: 18px;
  }
}

.button_banner {
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.addons {
  display: flex;
  justify-content: flex-end;
  @include mq(tablet, desktop){
    justify-content: space-between;
    padding: 16px 24px 0 18px;
  }
  @include mq($from: desktop) {
    padding-bottom: 0;
  }
}

.search {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.searchbox {
  @include mq($from: desktop) {
    width: 400px;
  }
}

.align_right {
  width: 100%;
  justify-content: flex-end;
}

.timezone {
  margin-left: 24px;
}

.buttons {
  @include mq($until: tablet){
    display: none;
  }
  @include mq($from: tablet){
    margin-left: 32px;
  }

  > button {
    float: right;
  }
}

.create_btn {
  @include mq($from: desktop){
    min-width: 212px;
  }
}

.sidebar_button {
  margin-left: 24px;
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.updater {
  z-index: 1;
  position: absolute;
  top: 50%;
  background: #FFFFFF;
  padding: 27px 77px 26px 64px;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%,0);
}

.expanded_sidebar {
  margin-left: -202px;
}

.expanded_subview {
  margin-left: 78px;
}

.expanded_sidebar_subview {
  margin-left: -50px;
}

.popout {
  :global(.ReactVirtualized__List) {
    opacity: .2;
    pointer-events: none;
  }
}

.content_inner {
  flex: 7 0 auto;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  //this height needs to be zero to prevent children from extending below the container, and therefore be unreachable.
  height: 0;
  margin-top: 16px;
}

.sidebar {
  @include mq($until: desktop) {
    position: fixed;
    top: 54px;
    bottom: 0;
    right: 0;
    box-shadow: -2px 0 4px 0 rgba(0,0,0,0.2);
  }
  @include mq($from: desktop) {
    margin-right: -27px;
  }
}

.hidden {
  display: none;
}

.actions_column {
  min-width: 159px;
  height: 48px;
  padding-top: unset;
  padding-bottom: unset;
  overflow: visible;
}

.header_actions_column {
  min-width: 159px !important;
}

.actions_btn {
  height: 48px !important;
}

.actions_wrapper {
  display: flex;
  align-items: center;
  height: $default_row_height;
  line-height: $default_row_height;
  @include mq($from: desktop){
    height: 48px;
    line-height: 48px;
  }
}

.addon_actions {
  margin-left: 24px;
  height: 40px;
  padding-right: 10px;
}

.confirmation {
  height: 100%;
  top: 0;
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.row_border {
  border: 1px solid $AU_blue_shade_3;
  height: 100%;
  display: flex;
  background-image: linear-gradient(to left, $error 100%, rgba($hover, 0) 0%);
  justify-content: flex-end;
  margin-right: 0;
}

.hover_margin {
  @include mq($from: desktop) {
    margin: 0 !important;
  }
}

.delete {
  height: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to left, $error 100%, rgba($hover, 0) 0%);
  @include mq($from: tablet) {
    background-image: linear-gradient(to left, $error 90%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 00%, rgba($hover, 0) 10%);
  }
}

.message {
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  background-image: linear-gradient(to left, $error 10%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 00%, rgba($hover, 0) 10%);

  button {
    font-size: 16px;
  }
}

.proceed {
  min-width: 125px;
  margin-left: 24px;
}

.cancel {
  margin: 0 32px 0 24px;
  width: 62px;
  padding: 0;
}

.msg {
  margin-left: 75px;
  color: $alert;
}

.count {
  font-weight: 600;
  margin: 0 5px;
}

.button {
  color: $link;
  cursor: pointer;
}

.loader {
  height: 33px;
  margin-right: 10px;
}

.text {
  display: flex;
}

.error {
  left: 30%;
  width: 700px;
  margin-left: unset;
  display: inline-block;
  &:before {
    @include au-icon("\27");
    margin-right: 10px;
    color: $alert;
  }
}

.search {
  width: 600px;
}

.cancel_button {
  composes: button;
  margin-left: 5px;
}

.search_box_status {
  @include font-size(14px, 20px);
  margin-top: 8px;
  position: absolute;
  top: 100%;
  width: 100vw;
  justify-content: flex-end;
  &:before {
    position: relative;
    line-height: 1;
    font-size: 14px;
    top: -1px;
  }
}

.left_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lookup_margin {
  margin-bottom: 16px;
}