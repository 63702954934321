@import 'src/css/globals.scss';
@import 'src/css/variables.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  display: flex;
  align-items: center;
  @include font-size(18px, 26px);
}

.crumb {
  display: flex;
  align-items: center;
  font-family: $global-font-sans;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &[data-tag]:before {
    display: inline-block;
    margin-right: 8px;
    content: attr(data-tag);
    font-family: $global-font-sans;
    font-weight: 600;
    @include font-size(8px, 11px);
    color: #FFFFFF;
    background-color: $beta-tag;
    border-radius: 8px;
    padding: 2px 5px 3px;
    text-transform: uppercase;
  }
}

a.crumb {
  &, &:hover, &:visited {
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include mq(tablet, tablet-ls){
      max-width: 300px;
    }
    @include mq(tablet-ls, desktop){
      max-width: 550px;
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  &:after {
    @include au-icon("\6c");
    font-size: 16px;
    display: inline-block;
    transform: rotate(270deg);
  }
}

.count_3 .crumb {
  @include mq(tablet, tablet-ls) {
    max-width: 380px;
  }
  @include mq(tablet-ls, desktop) {
    max-width: 400px;
  }
}

.count_4 .crumb {
  @include mq(tablet, tablet-ls) {
    max-width: 200px;
  }
  @include mq(tablet-ls, desktop) {
    max-width: 330px;
  }
}

.count_5 .crumb {
  @include mq(tablet, tablet-ls) {
    max-width: 200px;
  }
  @include mq(tablet-ls, desktop) {
    max-width: 250px;
  }
}
