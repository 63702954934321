@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$bg-color: #FFFFFF;
$border-color: $AU_blue_gray_1;
$row_height-desktop: 40px;

.container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $border-color;
  padding: 30px;
}

.break {
  border-bottom: 1px solid $border-color;
  margin: 24px 0;
}

.btn_container {
  margin: auto;
  display: table;
  margin-top: 24px;
}

.dropdown {
  border: 1px solid $border-color;
  background-color: white;
}

.container > .time_period_container {
  border-bottom: none;
  padding-bottom: 0;
}

.container > div:first-child {
  border-bottom: none;
}

.container > div > ul:first-child {
  margin-bottom: 0;
}

.calendar {
  order: 100;
  margin-top: -1px;
  background: $bg-color;
}

.list {
  list-style: none;
  margin: 0 8px;
  padding: 0 24px;
  overflow-x: hidden;
  width: 100%;
}

.input {
  border: none;
  outline: 0;
  box-shadow: none;
  color: $AU_blue_shade_2;
  padding: 0 16px;
  pointer-events: none;
  @include font-size(16px, 20px);
}

.placeholder_input {
  opacity: 0.6;
  @include font-size(14px, 20px);
}

.item {
  position: static;
  display: flex;
  @include font-size(16px, $row_height-desktop);
  > .link {
    padding-left: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .select {
    display: none;
    margin-left: auto;
    padding-left: 16px;
    text-transform: capitalize;
  }
  &:hover > .select {
    display: block;
  }

  .wrapper {
    display: block;
    position: absolute;
    z-index: 10;
    right: 32px;
    margin-top: -48px;
    box-sizing: border-box;
  }
}

.wrapper {
  position: relative;
}

.select {
  margin-left: 16px;
}

.link {
  display: block;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.selected {
  .link {
    padding-left: 32px;
    &:before {
      position: absolute;
      left: 0;
      width: 16px;
      height: 13px;
      @include au-icon("\23");
      @include font-size(13px, 40px);
    }
  }
}

.selection {
  display: flex;
  flex-wrap: nowrap;
  height: 62px;
  width: 100%;
  border: 1px solid $AU_blue_gray_1;
  border-radius: 2px;
  outline: 0;
  box-shadow: none;
  margin: auto;
  background-color: white;
  @include mq($from: desktop) {
    height: 50px;
  }
  :focus {
    background-color: $hover;
    border-color: $AU_blue_shade_1;
  }
  :active {
    background-color: $hover;
    border-color: $AU_blue_shade_1;
  }
  :visited {
    background-color: $hover;
    border-color: $AU_blue_shade_1;
  }
}

.separate {
  border-top: 1px solid $AU_blue_gray_1;
}

.subtitle {
  padding-bottom: 16px;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  border-left: 0;
  cursor: pointer;
  height: 60px;
  background-color: unset;
  pointer-events: none;
  margin-left: auto;
  @include mq($from: desktop) {
    height: 48px;
  }
  &:after {
    @include au-icon("\45");
  }
}