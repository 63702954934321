@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$table-color: $AU_blue_shade_2;
$table_bg-color: #FFFFFF;
$table_border-color: $AU_blue_gray_1;
$table_header_border-color: $AU_gray_shade_1;
$table_header_bg-color: #E7EAED;
$table_row_even_bg-color: $AU_gray_shade_3;
$table_row_odd_bg-color: #FFFFFF;

.table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
  color: $table-color;
  background-color: $table_bg-color;
  border-collapse: separate;
}

.row {
  @include font-size(16px, 24px);
  transition: background-color .1s ease-in-out;
  &:nth-child(even) .cell {
    background-color: $table_row_even_bg-color;
  }
  &:nth-child(odd) .cell:not(.header_cell) {
    background-color: $table_row_odd_bg-color;
  }
  &.dragging {
    display: table;
    border-collapse: collapse;
  }
  &.disabled {
    /* */
  }
  &:last-of-type {
    border-bottom: 0;
  }
}

.cell {
  padding: 20px 32px;
  vertical-align: top;
  background-color: $table_bg-color;
}

.header_cell {
  @include font-size(14px, 18px);
  font-family: $global-font-sans;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  border-bottom: 1px solid $table_header_border-color;
  background-color: $table_header_bg-color;
  position: sticky;
  z-index: 1;
  top: 0;
}

.expand_cell {
  padding-left: 12px;
  padding-right: 12px;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  width: 20px; height: 20px;

  &, &:active, &:visited, &:hover {
    color: $AU_blue_shade_0;
  }
}

.disabled .row {
  .toggle {
    pointer-events: none;
    &:before {
      color: $AU_blue_shade_1;
      opacity: 0.3;
    }
  }
}

.expanded {
  composes: toggle;
  &:before {
    @include au-icon("\e002");
  }
}

.collapsed {
  composes: toggle;
  &:before {
    @include au-icon("\e003"); 
  }
}

