@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.key_value{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 32px 25px;
}

.key_value_text{
  align-self: start;
  margin-bottom: 20px;
  &:before{
    @include au-icon("\e02A");
    margin-right: 10px;
    color: $global-badge-running
  }
}

.key_input{
  margin-bottom: 10px;
  width: 100%;
}

.value_input{
  margin-bottom: 25px;
  width: 100%;
}

.key_value_tg{

}