@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.section {
  background: $AU_gray_shade_3;
  margin-top: 32px;
}

.section:first-child {
  margin-top: 0;
}

.widget_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;
}

.content {
  flex: 1;
  position: relative;
}

.value, .device_type {
  &:after {
    content: ',';
    margin-right: 5px;
    }
}

.value:last-child, .device_type:last-child {
    &:after {
      content: unset;
    }
}

.device_type {
  text-transform: lowercase;
  color: $AU_blue_shade_3 !important;
}

.widget_section {
  display: flex;
  justify-content: center;
  @include mq($until: desktop) {
    flex-direction: column;
    align-items: center;
  }
}

.first_widget {
  @include mq($until: desktop) {
    margin-bottom: 13px;
  }
  @include mq($from: desktop) {
    margin-right: 13px;
  }
}

.second_widget {
  @include mq($until: desktop) {
    margin-top: 13px;
  }
  @include mq($from: desktop) {
    margin-left: 13px;
  }
}

.telemetry_section {
  composes: section;
  padding: 32px;
}

@for $i from 1 through 10 {
  .row#{$i} {
    height: 50px * $i;
    @include mq(tablet, tablet-ls) {
      height: 60px * $i;
    }
  }
}
.row {
  composes: row1
}

.column {
  :first-child {
    white-space: nowrap;
  }
  padding: 0 20px;
  border: 1px solid $AU_gray_shade_1;
}

.fieldname {
  composes: column;
  color: $AU_blue_shade_4;
  background-color: $AU_gray_shade_3;
  min-width: 140px;
  vertical-align: top;
  > span {
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 13px;
  }
}

.fieldvalue {
  composes: column;
  background-color: #FFFFFF;
  width: 100%;
}

.status_value {
  text-transform: capitalize;
}

.content_flex {
  display: flex;
  padding-top: 48px;
}

.table {
  flex-grow: 1;
}


.error {
  position: unset;
}