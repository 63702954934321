@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$title-color: $AU_blue_shade_2;

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 24px 32px;
  max-width: 100vw;
}

.title {
  @include mq($until: tablet){
    display: none;
  }
  @include mq($from: tablet){
    display: flex;
    align-items: center;
    color: $title-color;
    min-height: 48px;
    margin-bottom: 16px;
    @include font-size(18px, 26px);
  }
}

.form {
  > div:first-child { // fieldset__panel
    background-color: $AU_gray_shade_3;
    @include mq($until: tablet-ls) {
      padding: 8px 32px 24px;
    }
    @include mq($from: tablet-ls) {
      padding: 24px 32px;
    }
  }

  &.sticky {
    overflow-y: auto;
    > div:last-child { // fieldest__buttons
      z-index: 10;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 24px 32px;
      background-color: #FFFFFF;
      box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.2);
    }
  }
}
