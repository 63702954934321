@import "src/css/globals.scss";
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$border-color: $AU_blue_gray_1;
$selected_border-color: $primary_btn;

.container {
  flex-shrink: 0;
  background-color: unset !important;
  border-bottom: 1px solid $border-color;

  a, span {
    height: 36px !important;
    @include font-size(12px, 17px);
    &, &:active, &:hover, &:visited {
      border-style: solid;
      border-color: transparent;
      padding: 10px 21px 6px !important;
      &[aria-current], &.selected {
        border-width: 2px 1px;
        border-color: $border-color;
        position: relative;
        padding: 11px 23px 5px !important;
        border-top-color: $selected_border-color;
        border-bottom-color: #FFFFFF;
        &:after {
          position: absolute;
          content: "";
          display: block;
          left: 0;
          right: 0;
          bottom: -3px;
          height: 1px;
          background-color: #FFFFFF;
        }
      }
    }
  }
}

.selected {
  /* */
}
