
@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$table_border-color: $AU_blue_gray_1;
$table_row_bg-color: #FFFFFF;
$table_edit_header_border-color: $AU_gray_shade_0;
$table_new_row_border-color: $AU_blue_shade_3;
$table_extra_row_bg-color: $AU_gray_shade_3;
$sequence_bd-color: $AU_blue_shade_6;
$toggle_on_bg-color: $primary_btn;
$toggle_off_bg-color: $alert;
$handle_on_border-color: $AU_blue_gray_1;
$handle_off_border-color: $AU_blue_gray_1;
$label_border-color: $AU_blue_gray_1;
$disabled-color: $AU_gray_shade_0;
$disabled_bg-color: #FFFFFF;
$disabled_border-color: $AU_gray_shade_0;
$tablet_label-color: $AU_blue_shade_4;
$error: #FFF2F2;
$success-green: #5C872B;

.container {
  width: 100%;
}

.dropdown {
  min-width: unset;
  input {
    width: 100% !important;
  }
}

.table {
  table-layout: auto;
  position: relative;
  border-collapse: collapse;
  border: 1px solid $AU_blue_gray_1;
  &.dragging {
    border-collapse: separate;
  }
}

.view {
  .header_row .cell {
    border-color: $AU_gray_shade_0;
    @include mq(tablet, desktop) {
      display: none;
    }
  }
  .row .cell {
    border: 0;
  }
  .row, .header_row {
    border: none;
    /* sequence */
    .cell:first-child {
      text-align: left;
      padding-left: 20px;
      padding-right: 0;
    }
    .cell:nth-child(4) {
      text-align: center;
    }
  }
}

.create, .edit {
  .header_row, .row {
    /* sequence */
    .cell:first-child {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      @include mq(tablet, desktop) {
        background-color: $table_row_bg-color;
      }
    }
    .hide_column.cell:first-child {
      display: none;
    }
    .cell {
      background-color: $table_row_bg-color;
    }
  }
  .header_row {
    /* permit */
    .cell:nth-child(n+2) {
      @include mq(tablet, desktop) {
        padding: 0;
        text-align: center;
      }
    }
    /* delete */
    .cell:nth-child(n+4) {
      padding: 0;
      text-align: center;
    }
  }

  .row:last-of-type {
    border-bottom: 0;
  }

  .row {
    border-bottom: 1px solid $table_border-color;
    /* sequence */
    .cell:first-child {
      @include mq($from: desktop) {
      background-color: $sequence_bd-color;
      }
    }
    /* filterType, details, permit/deny, order */
    .cell:nth-child(n+2) {
      padding: 18px 24px;
    }
    /* actions*/
    .cell:nth-child(4) {
      text-align: center;
      @include mq(tablet, desktop) {
        padding-top: 9px;
      }
    }
    .cell:nth-child(n+5) {
      padding: 0;
    }
    /* sequence, filterType, details, permit/deny, order */
    .cell:nth-child(-n+5) {
      border-right: 1px solid $table_border-color;
    }
    &.new {
      .label:after {
        content: '';
      }
      .cell {
        padding: 20px 24px;
      }
      .cell:first-child {
        padding: 20px 0;
      }
      /* order mobile */
      .cell:nth-child(3) {
        @include mq(tablet, tablet-ls) {
          padding-top: 7px
        }
        @include mq(tablet-ls, desktop) {
          padding-top: 11px;
        }
        /* order mobile*/
        > div {
          @include mq(tablet, tablet-ls) {
            display: flex;
            @include mq(tablet, tablet-ls) {
              padding-top: 4px
            }
          }
        }
      }
      /* actions mobile */
      .cell:nth-child(n+4) {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        @include mq($until: desktop) {
          padding-top: 6px;
        }
      }
      /* order */
      .cell:nth-child(n+5) {
        @include mq(tablet, desktop) {
          padding-top: 10px;
        }
      }
    }
  }
}

.filter_type {
  width: 242px;
}

.tapfilter_type {
  width: 100%;
  text-align: left;
  @include mq(tablet, desktop) {
    margin-right: 24px;
   }
}

.create {
  .header_row {
    /* sequence */
    .cell:first-child {
      padding-left: 3px;
      padding-right: 0;
      border-right: 1px solid $table_border-color;
      @include mq(tablet, desktop) {
        text-align: left;
        padding-left: 24px;
      }
    }
  }
  .row {
    .icon_btn {
      margin-top: 5px;
    }
  }
}

.edit {
  border-width: 1px 0;
  border-top-color: $table_edit_header_border-color;
  .header_row {
    .cell {
      background-color: $table_row_bg-color !important;
      border-width: 1px 0;
      border-style: solid;
      border-color: $AU_gray_shade_0;
    }
    /* sequence */
    .cell:first-child {
      border-right: 0;
      /* filters for console */
      @include mq(tablet, desktop) {
        text-align: left;
        padding-left: 24px;
      }
    }
    /* order, delete for console*/
    .cell:nth-child(2) {
      @include mq(tablet, desktop) {
        text-align: center;
      }
    }
    /* actions for console*/
    .cell:last-child {
      @include mq(tablet, desktop) {
        text-align: center;
      }
      @include mq(tablet-ls, desktop) {
        width: 80px !important;
      }
    }
  }
  .row {
    /* sequence */
    .cell:first-child {
      border-left: 1px solid $table_border-color;
    }
    /* order */
    .cell:nth-child(3) {
      @include mq(tablet, desktop) {
        text-align: center;
      }
    }
    /* actions */
    .cell:last-child {
      border-right: 1px solid $table_border-color;
      @include mq(tablet, desktop) {
        text-align: center;
      }
    }
    /* order, delete */
    .cell:nth-child(n+5) {
      text-align: center;
      padding: 0;
    }
    /* order, delete for console*/
    .cell:nth-child(2) {
      @include mq(tablet, desktop) {
        text-align: center;
      }
    }
    &.new {
      /* filterType */
      .cell:nth-child(n-5) {
        border-right: 1px solid $AU_blue_gray_1;
      }
    }
    &.confirm, &.deleted {
      .cell {
        background-color: $error;
        border-top: 1px solid $table_new_row_border-color;
        border-bottom: 1px solid $table_new_row_border-color;
        > .inner:before {
          content: " ";
          position: absolute;
          left: -1px;
          right: -1px;
          top: -1px;
          z-index: 1;
          height: 1px;
          border-top: 1px solid $table_new_row_border-color;
        }
      }
      /* sequence */
      .cell:first-child {
        border-left: 1px solid $table_new_row_border-color;
      }
      /* actions */
      .cell:last-child {
        border-right: 1px solid $table_new_row_border-color;
      }
    }
    /* vin editor */
    &.extra {
      .cell {
        background-color: $table_extra_row_bg-color;
        border-width: 0 0 1px 0;
        padding: 0;
        &:first-child {
          @include mq(tablet, desktop) {
            display: none;
          }
          border-left: 1px solid $table_border-color;
        }
        &:nth-child(2) {
          @include mq(tablet, desktop) {
            display: none;
          }
        }
        &:last-child {
          @include mq(tablet, desktop) {
            display: none;
          }
          border-right: 1px solid $table_border-color;
        }
      }
    }
    .cell {
      position: relative;
    }
    &:not(.new) {
      .drag.disabled {
        display: none;
      }
    }
  }
}

.type_toggle {
  justify-content: center;
  margin: 10px 0;
  input {
    ~ .toggle_label {
      background-color: $toggle_off_bg-color;
    }
  }
}

.icon_btn {
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  user-select: none;
  text-align: center;
  padding: 0;
    @include mq($from: desktop){
      margin-top: 12px;
    }
  &, &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: $hover;
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.drag {
  composes: icon_btn;
  cursor: inherit;
  font-size: 18px;
  &:after {
    @include au-icon("\e02c");
  }
}

.delete {
  composes: icon_btn;
  cursor: pointer;
  font-size: 16px;
  &:after {
    @include au-icon("\69");
  }
}

.buttons {
  text-align: center;
  margin-top: 14px;
  @include mq($from: desktop) {
    margin-top: 19px;
  }
  > button:first-child {
    @include mq(tablet-ls, desktop) {
      margin-right: 16px;
    }
  }
}

.vin_actions {
  margin-top: 16px;
  @include mq(tablet, desktop) {
    margin-left: 113px;
    text-align: left;
  }
}

.delete_vins {
  margin-left: 16px;
}

.delete_vins, .add_vins {
  &:disabled {
    background-color: $disabled_bg-color;
    border: 1px solid $disabled_border-color;
    color: $disabled-color;
    opacity: 0.65;
  }
}

.vin_editor_actions {
  padding-top: 22px;
  padding-bottom: 24px;
}

.vin_add_btn, .vin_delete_btn {
  min-width: 166px;
}

.vin_editor {
  padding-top: 10px;
  @include mq(tablet, desktop) {
    padding-left: 18px;
  }  input {
    width: 100%;
  }
}

.btn {
  width: 93px;
}

.save_btn {
  composes: btn;
  font-size: 16px;
  margin-bottom: 16px;
}

.cancel_btn {
  composes: btn;
  width: auto;
  height: 24px;
  min-height: 24px;
  margin-top: 8px;
  margin-bottom: 22px;
  padding: 0;
  &:disabled {
    background-color: $disabled_bg-color;
    color: $disabled-color;
    opacity: 0.65;
  }
}

.no_filters {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  color: inherit;
}

.actions {
  &.fixed {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 500px;
  }
}

.confirmation, .deleting {
  height: 100%;
  display: flex;
  background-image: linear-gradient(to left, $error 100%, rgba($hover, 0) 0%);
  @include mq($from: tablet) {
    background-image: linear-gradient(to left, $error 90%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 00%, rgba($hover, 0) 10%);
  }
  align-items: center;
  justify-content: flex-end;
  margin-right: 0;
  button {
    font-size: 16px;
  }
}

.proceed {
  min-width: 125px;
}

.cancel {
  margin: 0 32px 0 24px;
  width: 62px;
  padding: 0;
}

.deleting_btn {
  min-width: 125px;
  margin-right: 118px
}

.vin_editor_cancel {
  padding: 0;
  margin-left: 24px;
}

.msg {
  margin-right: 24px;
}

.label {
  @include mq($until: desktop) {
    color: $tablet_label-color;
  }
  &:after {
    content: ":";
    @include mq($until: desktop) {
      margin-left: 3px;
    }
  }
  @include mq($until: tablet) {
    display: none;
  }
  @include mq(tablet, desktop) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 9px;
    margin-left: 24px;
  }
  @include mq(tablet, desktop) {
    min-width: 97px;
  }
}

.label_container, .type_container {
  display: flex;
  align-items: center;
}

.extra.mobile {
  .vin_editor {
    padding-left: 0;
  }
  .wrapper {
    position: absolute;
    padding: 0 44px;
    background-color: #F5F5F5;
    border-width: 0 0 1px 0;
    left: 0;
    right: 0;
  }
  position: relative;
  height: 271px;
  .cell:nth-child(3){
    position: static;
  }
  .cell:not(:nth-child(3)){
    visibility: hidden;
  }
}

.sticky_header {
  z-index: 10;
}

.value {
  @include mq($from: desktop) {
    margin-left: 10px;
  }
}

.flow_table_header_cell {
  @include mq(tablet, desktop) {
    display: table-cell !important;
  }
}