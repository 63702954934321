@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.label {
  text-transform: capitalize;
  margin-right: 15px;
  color: $AU_blue_shade_4;
  white-space: nowrap;
}

.container {
  display: flex;
}

.flow_label {
  composes: label;
  padding-left: 24px;
}

.flow_dropdown {
  width: 600px;
}

.shard_count_container {
  display: flex;
}

.hint {
  margin-left: 84px;
  &:before {
    color: $warning;
    @include au-icon("\6b");
  }
}

.error {
  @include font-size(14px, 20px);
  position: relative;
  margin-top: 8px;
  color: $alert;
  &:before {
    @include au-icon("\27");
    margin-right: 8px;
  }
}

.hint_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}