@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'src/css/tools/_tools.banner';

.container {
    flex: 1 0 0;
    display: flex;
    overflow: hidden;
    position: relative;
    @include mq($until: tablet) {
        padding-bottom: 10px;
    }

    margin-left: -32px;
    padding-left: 32px;
    margin-top: 16px;

    :global .o-wrapper {
        order: 30;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-top: 16px;

    }
}

.info_section {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    margin-top: 16px;
    padding: 16px 0;
}

.secret_rotation {
    margin-bottom: 32px;
}

.step {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
}

.step_header {
    @include font-size(16px, 22px);
    font-weight: 600;
    margin-right: 8px;
}

.step_description {
    opacity: 70%;
}

.generate_button {
    width: 189px;
    margin-top: 8px;
}

.head {
    margin-bottom: 8px;
}

.title {
    @include font-size(16px, 22px);
    font-weight: 600;
}

.banner {
    margin-top: 0;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    height: 100%;
    overflow-y: scroll;
    contain: strict;
    padding-right: 32px;
}

.delete_btn {
    float: right;

    &:before {
        @include au-icon("\69");
        width: 24px;
        height: 24px;
        font-size: 18px;
        color: #1B2F4B;
        margin-right: 8px;
    }
}

.rollback_btn {
    float: right;

    &:before {
        @include au-icon("\e085");
        width: 24px;
        height: 24px;
        font-size: 18px;
        color: #1B2F4B;
        margin-right: 8px;
    }
}

.disabled_btn {
    &:before {
        @include au-icon("\69");
        width: 24px;
        height: 24px;
        font-size: 18px;
        color: #1B2F4B;
        margin-right: 8px;
        opacity: 0.2;
    }
}

.disabled_text {
    color: #324661;
    opacity: 0.2;
}

.table_cell {
    border: 1px solid #D0D0D0;
}

.title_div {
    margin-bottom: 16px;
}

.dialog_credential {
    border-top: 1px solid #CFD5DC;
    border-bottom: 1px solid #CFD5DC;
    padding: 16px 0;
    margin-bottom: 24px;
}

.credential_label {
    display: inline-block;
    color: #6882A1;
    line-height: 27px;
    font-size: 16px;
    font-weight: 400;
}

.credential_value {
    display: inline-block;
    margin-left: 8px;
    font-weight: 600;
    color: #2D3F56;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.tooltip_container {
    position: absolute;
    z-index: 1;
    padding-top: 12px;
    max-width: 500px;
}

.tooltip {
    border: 1px solid #2D3F56;
    background-color: #ffffff;
    color: #324661;
    @include font-size(14px, 20px);
    min-width: 423px;
    margin-left: -351px;
    height: 52px;
    padding: 16px;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    transform: rotate(45deg) skew(18deg, 18deg);
    height: 12px;
    width: 12px;
    border: inherit;
    border-bottom: none;
    border-right: none;
    background: inherit;
}

.tooltip::before {
    @include au-icon("\e02a");
    height: 14px;
    width: 14px;
    color: #1C96CF;
    margin-right: 8px;
}