@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import "./entity_view.module.scss";

.container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.lookup_container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 80px 24px 32px;
  overflow-y: auto;
  scrollbar-width: none;

  &.isDrawerOpen {
    padding-right: 32px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.change_status_dialog_container {
  width: 471px;
}

.dialog_header {
  padding-bottom: 14px;
  padding-left: 0;
}

.change_status_dialog_header {
  composes: dialog_header;
  &:before {
    @include au-icon("\e03a");
    margin-right: 12px;
  }
}

.type {
  font-weight: 600;
}

.dialog_subtitle {
  margin-bottom: 28px;
  border-bottom: 1px solid $AU_blue_gray_1;
  padding-bottom: 28px;
}

.dialog_type {
  margin-right: 8px;
}

.dialog_content {
  color: $AU_blue_shade_2;
  line-height: 27px;
  font-weight: 400;
}

.row_content {
  margin-top: 8px;
  display: table;
}

.dialog_row {
  display: table-row;
}

.key {
  padding-top: 16px;
  padding-right: 16px;
  display: table-cell;
  text-align: right;
}