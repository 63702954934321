@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$information-icon: #1C96CF;

.container {
  margin-bottom: 16px;
}

.row_container {
  display: flex;
  flex-direction: column;
  background : #FFFFFF;
  border: 1px solid $AU_blue_gray_1;
  margin-bottom: 16px;
}

.row {
  display: flex;
}

.flow_container {
  display: flex;
  margin-bottom: 30px;
}

.editor_container {
  width: 100%;
  padding: 32px;
}

.filter_container {
  display: flex;
  align-items: flex-start;
  margin-right: 32px;
  margin-bottom: 40px;
}

.label {
  text-transform: capitalize;
  margin-right: 15px;
  color: $AU_blue_shade_4;
  white-space: nowrap;
}

.businessUnitRegionNotice {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  &:before {
    @include au-icon("\e02a");
    position: absolute;
    left: -24px;
    color: $information_icon;
  }
}

.flow_label {
  composes: label;
  padding-left: 10px;
}

.delete_column {
  width: 62px;
  display: flex;
  justify-content: center;
  border-left: 1px solid $AU_blue_gray_1;
}

.delete {
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  user-select: none;
  text-align: center;
  padding: 0;
    @include mq($from: desktop){
      margin-top: 12px;
    }
  &, &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  cursor: pointer;
  font-size: 16px;
  &:after {
    @include au-icon("\69");
  }
}

.checkbox {
  padding-left: 58px;

  & > input[type=checkbox]:not(:checked) + label:before {
    content: "";
    border: none;
    @include au-icon("\32");
  }
  & > input[type=checkbox]:checked + label:before {
    content: "";
    border: none;
    @include au-icon("\31");
  }
}

.override_fields {
  background-color: $AU_gray_shade_3;
  margin-left: 58px;
  margin-top: 28px;
  margin-right: 32px;
  padding: 40px;

  & > table {
    margin-bottom: 0;

    & > tbody > tr:not(:last-child) {
      border-bottom: 15px transparent solid;
    }
    & > tbody > tr > td {
      width: 100%;
      text-align: right;

      &:first-child {
        width: fit-content;
      }
      & > div {
        text-align: initial;
      }
    }
  }
}

.dropdown {
  width: 452px;
}

.flow_dropdown {
  margin-right: 16px;
  width: 510px;
}

.shardCount_dropdown > ul {
  max-height: unset !important;
}

.flow_inputs {
  display: flex;
  flex-direction: row;
}

.add {
  font-size: 16px;
}

.add:hover {
  color: $link;
}

.hint {
  margin-left: 84px;
  &:before {
    color: $warning;
    @include au-icon("\6b");
  }
}

.error {
  @include font-size(14px, 20px);
  position: relative;
  margin-top: 8px;
  color: $alert;
  &:before {
    @include au-icon("\27");
    margin-right: 8px;
  }
}

.hint_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}