@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$width: 52px;
$height: 52px;

.container {
  width: $width;
  height: $height;
  display: flex;
  background-color: $AU_gray_shade_3;
  border: 1px solid $AU_blue_gray_1;
}

.icon {
  flex: 1 0 auto;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: $AU_blue_shade_0;
  &:after {
    @include au-icon("\e02f");
    font-size: 16px;
  }
  &:hover {
    color: $link;
  }
}

.tooltip {
  position: absolute;
  right: 65px;
  top: 18px;
  padding: 14px 22px 24px;
  background-color: white;
  border: 1px solid $AU_blue_shade_4;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

  /* arrow shade */
  &:before {
    content : " ";
    width: 0; height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 18px solid $AU_blue_shade_4;
    position: absolute;
    top: 14px;
    right: -18px;
  }
  /* arrow */
  &:after {
    content : " ";
    width: 0; height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 14px solid white;
    position: absolute;
    top: 15px;
    right: -14px;
  }
}

.title {
  font-family: $global-font-sans;
  @include font-size(14px, 22px);
  color: $AU_blue_shade_2;
  text-align: center;
  font-weight: 600;
  margin-bottom: 14px;
  white-space: nowrap;
}

.entry {
  display: flex;
  margin-bottom: 9px;
  &:last-child {
    margin-bottom: 0;
  }
}

.symbol {
  width: 24px;
  height: 24px;
  cursor: default;
}

.label {
  color: $AU_blue_shade_1;
  font-family: $global-font-base;
  @include font-size(14px, 24px);
  margin-left: 7px;
  white-space: nowrap;
}
