@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';

$input_height: 60px;
$input_width: 611px;

.search {
  width: $input_width;
  height: $input_height;
  margin: 20px auto 40px;
  position: relative;
}

.empty:after {
  @include au-icon("\72");
  position: absolute;
  right: 20px;
  top: 22px;
  pointer-events: none;
}

.input {
  input {
    height: $input_height !important; /*override input height only on landing page*/
    padding-right: 60px !important;
  }
  button {
    top: 20px;
    right: 18px;
    &:after {
      font-size: 14px;
    }
  }
}
