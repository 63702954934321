@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';
@import 'src/css/variables.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$text-color: #FFFFFF;
$icon-success: #357262;
$alert-color: $alert;
$warning-color: #E49816;
$background-alert-color: #FFF2f2;
$background-warning-color: #FAF8F0;

$background-color: $AU_gray_shade_3;
$background_hover-color: #FFFFFF;
$background_hover_shadow-color: #9B9B9B;
$banner-info-border-color: #1C96CF;

@mixin width($n, $tile-width, $tile-width-desktop, $tile-width-tablet, $tile-width-mobile) {
  @include mq($until: tablet) {
    width: $tile-width-mobile;
  }
  @include mq(tablet, tablet-ls) {
    width: calc((#{$tile-width-tablet} + #{$tile-margin}) * #{$n});
  }
  @include mq(tablet-ls, large-desktop) {
    width: calc((#{$tile-width-desktop} + #{$tile-margin}) * #{$n});
  }
  @include mq($from: large-desktop) {
    width: calc((#{$tile-width} + #{$tile-margin}) * #{$n});
  }
}

@mixin tiles($n, $tile-width) {
  @include mq($until: tablet) {
    max-width: calc(100vw - 26px);
  }
  @include mq($from: tablet) {
    max-width: calc((#{$tile-width} + #{$tile-margin}) * #{$n});
  }
}

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.splash {
  @include mq($until: tablet) {
    display: none;
  }
  @include mq($from: tablet) {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    text-align: center;
    color: $text-color;
    font-family: $global-font-sans;
    background: $AU_blue_shade_0;
    width: 100vw;
    align-items: center;
  }

  h1 {
    font-weight: 600;
    margin-bottom: 56px;
    @include font-size(30px, 36px);
  }
}

.banner {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  background: url('../../images/lines.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  margin-top: 46px;
  height: 223px;
}

.tmc_container {
  position: relative;
}

.tmc {
  background: url('../../images/tmc.svg');
  height: 189px;
  width: 250px;
  background-repeat: no-repeat;
  position: absolute;
  top: -206px;
  left: -133px;
  background-size: cover;
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 18px;

  @include mq($from: large-desktop) {
    @include width(4, $tile-width, $tile-width-desktop, $tile-width-tablet, $tile-width-mobile);
  }
}

.tiles {
  $tile-width: $tile-width;
  $tile-width-tablet: $tile-width-tablet;
  $tile-width-desktop: $tile-width-desktop;
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  justify-content: center;
}

.current_status {
  background: #EDF7EB;
  display: flex;
  height: 215px;
  padding: 36px 24px 36px 61px;
  margin: 0 8px 24px;
  width: 100%;
  @include mq($from: desktop) {
    width: calc(314px + 314px + 16px);
  }
  @include mq(tablet-ls, desktop) {
    width: calc(472px + 472px + 16px);
  }
  @include mq($until: tablet-ls) {
    width: calc(344px + 344px + 16px);
  }
  @include mq($until: tablet) {
    width: calc(100vw - 48px);
  }
}

.background_alert {
  background: $background-alert-color;
}

.background_warning {
  background: $background-warning-color;
}

.icon {
  color: $icon-success;
  &:before {
    @include au-icon("\7d");
    /* dirty trick to fill the checkmark in white */
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 54 54' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='12' y='10' width='30' height='37' fill='%23FFF' /%3E%3C/svg%3E");
    position: relative;
    font-size: 60px;
    margin-right: 54px;
    top: 50px;
  }
}

.alert {
  composes: icon;
  &:before {
  @include au-icon("\36");
  color: $alert-color;
  /* dirty trick to fill the exclamation mark in white */
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 54 54' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='10' width='10' height='40' fill='%23FFF' /%3E%3C/svg%3E");
  }
}

.warning {
  composes: icon;
  &:before {
    @include au-icon("\37");
  }
  color: $warning-color;
}

.link_container {
  display: flex;
  flex-direction: column;
}

.description_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
  margin-bottom: 20px ;
}

.description {
  color: $AU_blue_shade_2;
  @include font-size(16px, 24px);
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
}

.description_alert {
  composes: description;
  color: $alert-color;
}

.description_warning {
  composes: description;
  color: $warning-color;
}

.incidents {
  @include font-size(16px, 24px);
  font-weight: 400;
  margin-top: 3px;
  margin-bottom: 0px;
  @include mq($until: tablet) {
    margin-bottom: 10px;
  }
}

.list {
  > a {
    @include font-size(16px, 28px);
  }
}

a:hover {
  text-decoration: none;
}

.tile {
  flex: 0 1 auto;
  margin: 0 8px 32px;
  background-color: $background_color;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 215px;
  padding: 24px 24px 20px 24px;
  @include mq($until: tablet) {
    width: $tile-width-mobile;
      }
  @include mq(tablet, tablet-ls) {
    width: $tile-width-tablet;
  }
  @include mq(tablet-ls, desktop) {
    width: $tile-width-desktop;
  }
  @include mq($from: desktop) {
    min-width: $tile-width;
  }
}

.hoverable_tile {
  composes: tile;
  &:hover {
    background-color: $background_hover-color;
    box-shadow: 0 0 8px 0 $background_hover_shadow-color;
  }
}

.taps_tile {
  composes: hoverable_tile;
  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;

  .label {
    width: auto;
  }
  .counter {
    margin-left: auto;
  }
}

.label {
  width: 80px;
  color: $AU_blue_shade_2;
  white-space: nowrap;
  @include mq($from: desktop) {
    width: 75px;
  }
  &:after {
    content: ": "
  }
}

.status_list {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 28px;
  > div {
    width: unset;
    padding-top: 0;
    > span::before {
      font-size: 16px;
    }
  }
}

.tap_list_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link_description {
  composes: description;
  opacity: .6;
  margin-left: 3px;
  font-weight: unset;
  @include font-size(14px, 20px);
}

.current_status_tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  display: flex;
  @include font-size(20px, 30px);
  margin-bottom: 10px;
  color: $AU_blue_shade_3;
}

.current_status_title {
  composes: title;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.list_container {
  margin-left: 17px;
}

.incident {
  position: absolute;
  top: 58px;
  color: $AU_blue_shade_1;
  font-weight: 600;
}

.announcement {
  padding: 5px 0;
}

.announcements {
  display: inline-block;
  width: 100vw;
  margin: 0;
  padding: 24px 0 20px 0;
  border-left: none;
  &:before {
    display: none;
  }
  &:after {
    @include au-icon("\e02a");
    padding-left: 32px;
    position: absolute;
    top: 28px;
    color: $banner-info-border-color;
    font-size: 24px;
    @include mq($from: tablet-ls) {
      top: 27px;
    }
  }
  > div {
    padding-left: 72px;
    margin: 0;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    @include mq($from: tablet-ls) {
      display:flex;
    }
      > h2 {
      margin-bottom: 0px;
      @include font-size(16px, 20px);
      &:after {
      content: '-';
      margin: 0 5px;
      font-weight: 600;
      }
    }
  }
}

.title {
  font-weight: 600;
}

.duration {
  display: flex;
  @include mq($until: tablet) {
    flex-direction: column;
  }
  flex-direction: row;
  @include font-size(16px, 20px);
}

.details {
  @include font-size(16px, 20px);
}

.start_time {
  @include mq($until: tablet) {
    margin-left: 0;
  }
  margin-left: 10px;
  &:after {
    content: '-';
    margin: 0 5px;
  }
}

.end_time {
  margin-right: 10px;
}


.icon_INVALID {
  &:before {
    @include au-icon("\e035");
    color: $alert;
    font-size: 16px;
    background: $global-badge-invalid;
    margin-right: 8px;
  }
  > span::before {
    display: none;
  }
}

.icon_RUNNING {
  > span::before {
    color: $global-badge-running;
  }
}

.icon_PAUSED {
  > span::before {
    color: $landing-page-paused;
  }
}

.icon_STOPPED {
  > span::before {
    color: $alert;
  }
}

.action_row {
  overflow: hidden;
  max-width: 100vw;
  text-align: center;
}

.action_block {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  text-align: left;
  box-sizing: border-box;
  height: 242px;
  border: 1px solid #CFD5DC;
  vertical-align: top;
  padding: 30px 24px 0 40px;
  margin-bottom: 32px;

  @include mq($from: desktop) {
    width: $tile-width;
    margin-right: 16px;
  }

  @include mq($until: tablet) {
    width: calc(100vw - 48px);
  }

  @include mq(tablet, tablet-ls) {
    width: 344px;
    margin: 8px;
  }

  @include mq(tablet-ls, desktop) {
    width: 472px;
    margin: 8px;
  }
}

.entity_title {
  display: inline-block;
  margin-left: 16px;
  @include font-size(20px, 28px);
}

.entity_text {
  @include font-size(14px, 20px);
  height: 60px;
}

.entity_icon {
  height: 30px;
  display: inline-block;
  @include font-size(24px, 24px);
}

.clients_icon {
  composes: entity_icon;
  &:after {
    @include au-icon("\e036")
  }
}

.groups_icon {
  composes: entity_icon;
  &:before {
    @include au-icon("\e03b");
  }
}

.taps_icon {
  composes: entity_icon;
  &:before {
    @include au-icon("\e03c");
  }
}

.flows_icon {
  composes: entity_icon;
  &:before {
    @include au-icon("\e03d");
  }
}

.action_btn {
  display: inline-block;
  min-width: 75px;
  @include font-size(16px, 24px);
}

.manage_btn {
  composes: action_btn;
  border: none;
  color: $link;
  margin-left: 16px;
}

.topology_tile {
  border: 1px solid $AU_blue_gray_1;
  margin: 32px 0;
  text-align: center;
  padding-bottom: 25px;

  @include mq($until: desktop) {
    display: none;
  }

  @include mq($from: desktop) {
    width: 1304px;
  }

  @include mq($until: tablet-ls) {
    width: calc(344px + 344px + 16px);
    //temporary fix for responsiveness: hiding topology legend on tablet and smaller screens
  }

  @include mq(tablet-ls, desktop) {
    width: calc(472px + 472px + 16px);
  }
  @include mq($until: tablet) {
    width: calc(100vw - 48px);
  }
}

.tile_title {
  @include font-size(20px, 28px);
  font-weight: 600;
  margin-top: 32px;
  text-align: center;
  color: $AU_blue_shade_2;
}

.topology_link {
  @include font-size(16px, 24px);
  margin-top: 10px;
  color: $link;
}

.tile_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tile_divider {
  min-width: 100vw;
  width: 100vw;
  height: 16px;
  opacity: 0.19;
  background: linear-gradient(180deg, #A9A9A9 0%, #FFFFFF 100%);
  transform: scaleY(-1);
}

.actions_title {
  composes: tile_title;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 100vw;
}

.quick_actions_row {
  composes: tile_row;
  @include mq($from: desktop) {
    margin-left: 16px;
  }
}
