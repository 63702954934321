@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';

.detail_popout {
  .button {
    position: absolute;
    top: 30px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 32px;
    line-height: 32px;
    font-size: 15px;
    z-index: 2;

    &:after {
      @include au-icon("\65");
    }

    &:hover {
      cursor: pointer;
      background-color: $AU_gray_shade_1;
    }
  }
}