@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  display: flex;
  flex-direction: column;
  @include font-size(16px, 24px);
}

.container > div:not(:last-child) {
  margin-bottom: 4px;
}