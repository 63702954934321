@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$default_row_height: 48px;
$icon-color: #1C96CF;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  @include mq($from: desktop){
    margin: 0 32px 10px;
  }

  :global .o-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 16px;
    border-top: 2px solid $AU_blue_gray_1;
  }
  > div {
    > div {
      /* Devices and Vehicles pages */
      @include mq($until: desktop) {
        margin: 24px 25px 8px 32px;
      }
    }
  }
}

.header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  + :global(.o-wrapper) {
    margin-top: 16px;
  }
}

.addons {
  display: flex;
  align-items: center;
}

.buttons {
  @include mq($until: desktop){
    display: none;
  }
  @include mq($from: desktop){
    margin-left: 24px;
    height: $default_row_height;
  }
}

.form {
  display: flex;
  flex-direction: column;
  background-color: $AU_gray_shade_3;
  justify-content: center;
}

.form_embedded {
  composes: form;
  padding: 30px 60px 30px;
}

.extras {
  display: flex;
  flex-direction: column;
  margin: 24px auto;
}

.label {
  color: $AU_blue_shade_2;
  font-weight: 600;
  font-family: $global-font-sans;
  @include font-size(14px, 18px);
  margin-bottom: 8px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control {
  display: flex;
  flex-direction: column;
  + .control {
    margin-left: 16px;
  }
}

.input {
  white-space: nowrap;
  justify-content: inherit;
  min-width: unset;
  @include mq($until: tablet) {
    width: 178px;
  }
  @include mq(tablet, desktop) {
    width: 454px;
  };
  @include mq($from: desktop){
    width: 600px;
  }

  & input[type="text"] {
    height: 60px;
  }
}

.button {
  width: 178px;
}

.button_embedded {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 60px;
}

.info {
  display: flex;
  align-items: center;
  height: 20px;
  @include font-size(14px, 20px);
  &:before {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: $icon-color;
    margin-right: 10px;
    @include au-icon("\e02a");
  }
}


.error_text {
  position: absolute;
  top: 60px;
}