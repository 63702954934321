@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  margin: 0 -20px
}

.table {
  border-collapse: collapse;
  border: 0;
}

.row .cell {
  padding-top: 12px;
  padding-bottom: 12px;
}

.row {
  &:nth-child(even) {
    .cell {
      background-color: $AU_gray_shade_3;
    }
  }
}

.hint {
  opacity: .65;
  margin-left: 8px;
  font-family: $global-font-base;
  font-weight: normal;
  @include font-size(14px, 18px);
}

.type {
  composes: cell;
  width: 316px;
}

.device {
  composes: cell;
}
