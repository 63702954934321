@import 'node_modules/@au/core/src/css/settings/_settings.core';
@import 'node_modules/@au/core/src/css/settings/_settings.global';
@import 'node_modules/@au/core/src/css/settings/_settings.colors';

$global-icon-background_white: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='3' width='10' height='10' fill='%23FFF' /%3E%3C/svg%3E");
$global-round-icon-background_white: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23FFF' /%3E%3C/svg%3E");

$global-badge-running: #4FAAD7;
$global-badge-invalid: #FFF2F2;
$global-badge-starting: #E7A32D;
$global-badge-success-green: #5C872B;

$global-running-icon: #1A678C;
$global-valid-icon: #618B32;
$global-paused-icon: #000000;
$global-stopped-icon: #2B0105;
$global-starting-icon: #B57B05;


$landing-page-paused: #2D3F56;
