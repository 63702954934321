@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

.icon {
  &:before {
    margin-right: 8px;
  }
}

.disconnected {
  composes: icon;
  line-height: normal;
  display: inline-block;
  background-color: $alert;
  color: white;
  font-weight: bold;
  font-size: 10px;
  padding: 5px 12px 5px 12px;
  text-transform: uppercase;
  &:before {
    @include au-icon("\e054");
    color: white;
    background-color: black;
    border-radius: 50%;
    padding: 1px;
    font-size: 14px;
  }
}

.connected {
  composes: icon;
  line-height: normal;
  display: inline-block;
  background-color: $global-badge-success-green;
  color: white;
  font-weight: bold;
  font-size: 10px;
  padding: 5px 12px 5px 12px;
  text-transform: uppercase;
  &:before {
    @include au-icon("\e053");
    color: white;
    background-color: #3B5D13;
    border-radius: 50%;
    padding: 1px;
    font-size: 14px;
  }
}

.unknown_event {
  composes: icon;
  line-height: normal;
  display: inline-block;
  background-color: white;
  color: #9B9B9B;
  border: 1px solid #D0D0D0;
  font-weight: bold;
  font-size: 10px;
  padding: 5px 12px 5px 12px;
  text-transform: uppercase;
  &:before {
    @include au-icon("\e051");
    color: #9B9B9B;
    border-radius: 50%;
    padding: 1px;
    font-size: 14px;
  }
}
