@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

.container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-right: 16px;
  width: 100%;
  @include mq($until: desktop) {
    padding-right: 24px;
  }
}

.hint_container {
  display: flex;
  align-items: baseline;
}

.icon {
  &:before {
    @include au-icon("\e02a");
    margin-right: 8px;
    font-size: 14px;
    color:#1C96CF;
  }
}