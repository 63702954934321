@import 'node_modules/@au/core/src/css/settings/settings.colors';

@import 'src/css/globals.scss';
@import 'src/css/tools/_tools.banner';

$banner-info-border-color: #1C96CF;
$banner-info-field-color: #EEF7FF;
$banner-warning-border-color: #E49816;
$banner-warning-field-color: #FFF7E7;
$banner-alert-border-color: $alert;
$banner-alert-field-color: #FFF2F2;
$banner-success-border-color: #357262;
$banner-success-field-color: #EDF7EB;
$banner-loading-border-color: $AU_gray_shade_1;
$banner-loading-field-color: #F5F5F5;

.banner {
  width: 100%;
  margin-bottom: 16px;
}

.banner-warning {
  @include au-banner('~', $banner-warning-border-color, $banner-warning-field-color);
}
.banner-info-circle {
  @include au-banner('\2e', $banner-info-border-color, $banner-info-field-color);
}
.banner-info-square {
  @include au-banner('\e02a', $banner-info-border-color, $banner-info-field-color);
}
.banner-alert {
  @include au-banner('^', $banner-alert-border-color, $banner-alert-field-color);
}
div.banner-success {
  @include au-banner('}', $banner-success-border-color, $banner-success-field-color);
}
div.banner-loading {
  @include au-banner(none, $banner-loading-border-color, $banner-loading-field-color);
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.spinner {
  height: 24px;
  width: 24px;
  margin-right: 15px;
}

.message {
  color: $AU_blue_shade_2;
}
