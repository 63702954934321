@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  padding: 0 24px;
  + .container {
    margin-top: 24px;
  }
}

.title {
  @include font-size(14px, 18px);
  font-family: $global-font-sans;
  font-weight: 600;
  position: relative;
  margin-bottom: 8px;
  &:before {
    position: absolute;
    left: -18px;
    top: calc(50% - 2px);
    content: " ";
    width: 4px;
    height: 4px;
    border-radius: 8px;
    background-color: $AU_blue_shade_0;
  }
}

.content {
  font-weight: 400;
  display: flex;
  @include font-size(16px, 27px);
}

.name {
  white-space: nowrap;
  + .value {
    margin-left: 8px;
  }
}