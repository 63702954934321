@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$text-white: #FFFFFF;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stopped {
  background: $alert;
  color: $text-white;
  > div {
    > span {
      &:before {
        color: $global-stopped-icon;
      }
    }
  }
}

.tap_container {
  display: flex;
  flex-direction: row;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.view {
  margin-bottom: 16px;
  &:not(:last-of-type) {
    margin-top: 16px;
  }
}

.details {
  margin-left: 15px;
}
