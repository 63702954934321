@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$info_bg-color: #EEF7FF;
$info_border-color: #1C96CF;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 62px;
}

.actions  {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.pipe {
  width: 1px;
  height: 24px;
  background: $AU_blue_shade_2;
  margin: 0 16px;
}

.action {
  font-weight: normal;
  font-family: $global-font-base;
  padding: 0;
  @include font-size(16px, 27px);
}

.filter_invalid {
   /* dirty trick to match the mocks. Ideally we should update au_toggle.scss */
  transform: scale(1.25);
  margin-left: auto;

  .filter_invalid_label {
    background-color: $AU_gray_shade_0;
  }

  .filter_invalid_switch {
    input:checked ~ .filter_invalid_label {
      background-color: $secondary_btn;
    }
  }
}

.filter_invalid_caption {
  color: $AU_blue_shade_2;
  margin-left: 16px;
}

.table {
  border: 0;
}

.header_row .cell {
  background-color: white;
  border: 2px solid $AU_blue_gray_1;
  border-collapse: unset;
  height: 44px;
  line-height: 44px;
  border-left: 0;
  border-right: 0;
}

.row {
  &, &:nth-child(2n) {
    .cell, > td:first-child {
      background-color: #FFFFFF;
    }
  }
}

.row, .header_row {
  /* toggle */
  .cell:first-child {
    padding-left: 0;
    padding-right: 0;
    padding-top: 32px;
    text-align: left;
  }
}

.row .cell {
  padding-top: 32px;
  padding-bottom: 32px;
}

.row:not(:nth-last-child(2)) {
  border-bottom: 1px solid $AU_gray_shade_2;
}

.row.hidden {
  &, + .expansion_row {
    display: none;
  }
}

.row + .expansion_row {
  .container {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  };

  &:empty + .row .cell {
    border-top: 1px solid $AU_gray_shade_2;
  }

  > .cell {
    &, &:nth-child(2n+1) {
      background-color: $AU_gray_shade_3;
      position: relative;
      padding: 32px;
    }
  }

  /* subtables */
  .header_row {
    display: none;
  }

  .row {
    /* toggle */
    > .cell:first-child {
      padding-left: 32px;
      padding-right: 16px;
    }
    &:nth-child(n+3) .cell {
      border-top: 1px solid $AU_gray_shade_2;
    }
  }
  
  .expansion_row .cell {
    background-color: #FFFFFF;
    padding: 0 64px 32px;
    border: 0;
  }
}

.content {
  min-height: 40px;
}

.no_rows {
  text-align: center;
}