@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'src/css/components/entity_policies.module.scss';

.content {
  flex: 1;
  position: relative;
}

.section {
  padding-top: 16px;
}

.appliedPolicy {
  padding-bottom: 16px;
}

.panel {
  > div:first-child {
    background: none;
    padding: 0;
    text-transform: none;
    height: 24px;
    > div:first-child {
      font-weight: 400;
      &:before {
        right: unset;
        width: 16px;
        height: 16px;
        margin-right: 16px;
        @include au-icon("\21");
        font-size: 16px;
        color: $AU_blue_shade_1;
      }
    }
    &:after {
      content: none;
    }
  }
  > div:last-child {
    padding-top: 18px;
    padding-left: 32px;

    border-left: 1px dashed;
    margin-left: 8px;
    table {
      margin-bottom: 0;
    }
  }
}

.collapsed {
  max-height: 72px;
  overflow: visible;
  margin-bottom: 0;
  
  /* title */
  > div:first-child {
    > div:first-child:before {
      @include au-icon("\e07b");
    }
  }
  > div:last-child {
    display: none;
  }
}

.history {
  padding: 24px 32px;
  background: #F5F5F5;
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 600;
    display: flex;
  }
  .actions {
    display: flex;
    font-weight: 400;
  }
  .pipe {
    width: 1px;
    height: 24px;
    background: $AU_blue_shade_2;
    margin: 0 16px;
  }
  .action {
    font-weight: normal;
    font-family: $global-font-base;
    padding: 0;
    font-size: 16px;
    min-height: 24px;
    height: 24px;
  }
  .items {
    margin-top: 24px;
  }  
}

.headername {
  min-width: 200px;
  max-width: 450px;
  font-weight: 600;
  padding: 10px 28px 10px 24px;
  border-top: 2px solid #9B9B9B;
  border-bottom: 2px solid #9B9B9B;
}

.fieldvalue {
  width: 50%;
}

.noitems {
  padding: 10px 28px 10px 24px;
}

.statement {
  &:before {
    content: "";
    border-left: 1px dashed;
    margin-left: 8px;
  }
  &:first-child:before {
    border-left: none;
  }
}

.content_flex {
  display: flex;
  padding-top: 48px;
  height: 100%;
}

.banner {
  margin-top: 0;
}