@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$border-color: $AU_blue_gray_1;
$icon-color: #5C872B;

.container {
  width: 500px;
  padding: 48px 40px;

  &:before {
    top: unset;
    content: "\7d";
    color: $icon-color;
  }
}

.info {
  padding: 14px 0;
  border-color: $border-color;
  border-style: solid;
  border-width: 1px 0 1px 0;
  @include font-size(16px, 27px);
}

.description {
  margin-top: 28px;
}

.button {
  min-width: 102px;
  min-height: 48px;
  height: 48px;
}