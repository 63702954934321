@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$form-width: 594px;

.container {
  display: flex;
  width: 100%;
}

.form_container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding: 0 32px;
}

.breadcrumbs {
  margin-top: 46px;
  margin-bottom: 36px;
}

.details_box {
  display: grid;
  grid-template-columns: fit-content(100px) 1fr;
  padding: 24px;
  background-color: $hover;
}

.detail_name {
  margin: 6px 16px 6px 0;
  text-align: right;
  color: $AU_blue_shade_4;
}

.detail_value {
  margin-top: 6px;
  margin-bottom: 6px;
  color: $AU_blue_shade_2;
}

.import {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 36px 0;
  border: 1px solid $AU_blue_gray_1;
  padding: 20px 24px;

  .import_text {
    font-size: 14px;
    margin-right: 32px;

    &:before {
      @include au-icon("\e02a");
      margin-right: 8px;
      color: #1C96CF;
    }
  }
}

.import_header {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 0;
  font-size: 18px;
}

.import_notice {
  padding-bottom: 18px;
  font-size: 14px;

  &:before {
    @include au-icon("\e02a");
    margin-right: 6px;
    font-size: 12px;
    color: #1C96CF;
  }
}

.import_json {
  height: calc(80vh - 250px); //250 being roughly the height of the other elements in the import window. Actual is 274.7.
  width: 744px;
  &.maximized {
    top: 54px;
    left: 0;
    padding: 30px 40px 40px;
    height: calc(100vh - 222px);
    width: calc(100vw - 81px);
    z-index: 5;
  }
}

.controls {
  position: sticky;
  display: flex;
  align-items: center;
  bottom: 0;
  padding-top: 24px;
  padding-bottom: 40px;
  background-color: white;
  z-index: 1;
}

.save_button {
  margin-right: 5px;
  padding: 13px 32px;
  border-radius: 4px;
}

.update_button {
  position:absolute;
  right: 0px;
  padding: 14px 32px;
  color: $link;
  border-color: $link;
  border-radius: 4px;
  &.inactive {
    cursor: auto;
    color: $AU_gray_shade_0;
    border: 1px solid $AU_gray_shade_0;
    background-color: #FFFFFF;
  }
}

.section_container {
  margin-bottom: 45px;
}

.section_header_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 36px 24px;
  background-color: $AU_gray_shade_3;
}

.section_header {
  font-weight: bold;
  cursor: pointer;

  &:before {
    @include au-icon("\e003");
    margin-right: 16px;
  }
  &.expanded:before {
    @include au-icon("\e002");
  }
}

.json_button {
  color: $link;
  cursor: pointer;
}

.section_content {
  border: 1px solid $AU_gray_shade_2;
  border-top: none;
  padding: 32px 24px 24px;
}

.field_label {
  padding: 8px 0;
  @include font-size(16px, 27px);
  color: $AU_blue_shade_0;
}

.info {
  font-size: 14px;

  &:before {
    @include au-icon("\e02a");
    margin-right: 8px;
    color: #1C96CF;
  }
}

.field_sub_label {
  font-size: 14px;
  color: $AU_blue_shade_2;
  opacity: .6;
}

.radio_container {
  display: flex;
  margin-bottom: 32px;
  border: 1px solid $AU_gray_shade_2;
  padding: 20px 24px;
  max-width: $form-width;

  div {
    margin-right: 36px;
  }
}

.expiration_container {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 32px;
  border: 1px solid $AU_gray_shade_2;
  padding: 16px 24px;
  max-width: $form-width;
}

.time_field {
  width: 72px;
  padding-right: 6px;
}

.time_label {
  padding-right: 16px;
  color: $AU_blue_shade_2;
  opacity: .5;
}

.time_error {
  position: absolute;
  bottom: 0;
  left: 24px;
  font-size: 12px;
  color: $alert;
}

.table_container {
  margin-bottom: 32px;
  max-width: $form-width;
  border-top: 2px solid $AU_blue_gray_1;
}

.header_cell {
  background: #FFFFFF;
  border-bottom: 2px solid $AU_blue_gray_1;
}

.centered_cell {
  composes: header_cell;
  text-align: center;
}

.cell {
  border-right: 1px solid $AU_blue_gray_1;
  border-bottom: 1px solid $AU_blue_gray_1;
}

.priority_cell {
  composes: cell;
  border-left: 1px solid $AU_blue_gray_1;
  background-color: $AU_gray_shade_3 !important;
}

.white_cell {
  composes: cell;
  background: #FFFFFF !important;
}

.drag_cell {
  text-align: center;

  cursor: pointer;
  &:before {
    @include au-icon("\e02c");
    color: $AU_blue_shade_0;
  }
}

.remove_cell {
  composes: white_cell;
  text-align: center;

  div {
    cursor: pointer;

    &:before {
      @include au-icon("\69");
      color: $AU_blue_shade_0;
    }
  }
}

.description_field {
  margin-bottom: 32px;
}

.json {
  height: 375px;

  &.maximized {
    position: fixed;
    top: 54px;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
  }
}

.definitions_container {

  .definition:last-child {
    margin-bottom: 0;
  }
}

.definition {
  margin-bottom: 56px;
}

.version_dropdown {
  width: 120px;
  margin-right: 8px;
  min-width: unset;
}

.definition_inputs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.error_margin {
  margin-bottom: 40px;
}

.definition_remove {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: $AU_blue_shade_2;
  cursor: pointer;
  width: 50px;

  &.disabled {
    opacity: .5;
    cursor: auto;
  }
  &:before {
    @include au-icon("\69");
    font-size: 20px;
  }
}

.definition_reset {
  composes: definition_remove;
  &:before {
    @include au-icon("\e085");
    font-size: 20px;
  }
}

.definition_add {
  margin-top: 32px;
  width: fit-content;
  color: $link;
  cursor: pointer;

  &.disabled {
    color: $AU_blue_gray_1;
    &:before {
      color: $AU_blue_gray_1;
    }
  }
  &:before {
    @include au-icon("\74");
    margin-right: 10px;
    color: $AU_blue_shade_0;
  }
}

.force_save_header {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 0;

  &:before {
    @include au-icon("\36");
    margin-right: 10px;
    color: $alert;
  }
}

.exit_header {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 0;

  &:before {
    @include au-icon("\37");
    margin-right: 10px;
    color: $warning;
  }
}

.dialog_subtitle {
  margin-bottom: 28px;
  border-bottom: 1px solid $AU_blue_gray_1;
  padding-bottom: 28px;
}

.dialog_type {
  margin-right: 8px;
}

.dialog_container {
  width: 478px;
}

.validate_message {  
  padding: 24px;
  margin: 24px 0;
  border-left: 5px solid #A22330;
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #324661;
  line-height: 27px;
  &:before {
    padding-right: 20px;
    font-size: 20px;
  }
}

.validate_success_message {
  composes: validate_message;
  background-color: #EDF7EB;
  border-left-color: #357262;
  &:before {
    @include au-icon("\e052");
    color: #5B8037;
  }
}

.validate_failure_message {
  composes: validate_message;
  background-color: $global-badge-invalid;
  &:before {
    @include au-icon("\27");
    color: $alert;
  }
}

.protocol_type_input {
  width: 100px;
  margin-right: 8px;
}

.protocol_subtype_input {
  width: 242px;
  margin-right: 8px;
}

.options {
  width: unset;
}

.error {
  position: absolute;
  width: $form-width;
  top: 60px;
  @include mq($from: desktop) {
    top: 50px;
  }
}