@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$color: #FFFFFF;
$incident-color: $alert;
$announcement-color: #4FAAD7;
$today-color: #92AF5A;

.container {
  display: flex;
  flex-direction: column;

  :global {
    .DayPicker-Day {
      @include font-size(14px, 20px);
      @include mq($until: large-desktop) {
        width: 38px;
        height: 38px;
      }
      @include mq($from: large-desktop) {
        width: 58px;
        height: 58px;
      }
      border: 1px solid $AU_gray_shade_1;
      position: relative;
    }
    .DayPicker-Caption {
      margin-bottom: 18px;
    }
    .DayPicker-Day--outside {
      background-color: $AU_gray_shade_2;
    }
    .DayPicker-Day--disabled {
      color: rgba($AU_blue_shade_2, .5);
    }
    .DayPicker-Weekday abbr[title] {
      @include font-size(11px, 15px);
    }
    .DayPicker {
      &-wrapper, &-Months {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
      }
      &-Month {
        margin: 0;
        width: 100%;
      }
    }
    .DayPicker-Day--announcement:after,
    .DayPicker-Day--incident:after,
    .DayPicker-Day--today:after {
      content: " ";
      border: 5px solid transparent;
    }

    .DayPicker-Day--announcement:after {
      border: 5px solid transparent;
      border-right-color: $announcement-color !important;
      border-bottom-color: $announcement-color !important;
    }
    .DayPicker-Day--incident:after {
      border: 5px solid transparent;
      border-right-color: $incident-color !important;
      border-bottom-color: $incident-color !important;
    }
    .DayPicker-Day--today:after {
      border: 5px solid transparent;
      border-right-color: $today-color !important;
      border-bottom-color: $today-color !important;
    }

    .DayPicker-Day--outside {
      background-color: $AU_gray_shade_2;
      &:after {
        border: none;
      }
    }
  }
}

.daypicker {
  flex: 1 0 auto;
  display: flex;
  padding: 45px 32px 0;

  :global {
    .DayPicker-Day--announcement:after,
    .DayPicker-Day--incident:after,
    .DayPicker-Day--today:after {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  + .hint {
    margin-top: -40px;
  }
}

.legend {
  display: flex;
  justify-content: center;
  margin: 25px 0;
  padding: 0;
}

.entry {
  display: flex;
  align-items: center;
  @include font-size(12px, 22px);
  color: $AU_blue_shade_2;
  font-weight: normal;
  &:after {
    order: -1;
    margin-top: -2px;
    content: "";
    border: 5px solid transparent;
    border-right-color: $alert!important;
    border-bottom-color: $alert!important;
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
  + .entry {
    margin-left: 16px;
  }
}

.hint {
  margin: 0 auto;
  opacity: .7;
  color: $AU_blue_shade_1;
  @include font-size(12px, 20px);
  padding-bottom: 30px;
}
