@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';

$alert-color: $alert;
$warning-color: #E49816;

.icon > a {
  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 9px;
    right: 16px;
    font-size: 16px;
  }
}

.alert{
  composes: icon;
  > a:after {
    @include au-icon("\36");
    color: $alert-color;
    /* dirty trick to fill the exclamation mark in white */
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7' y='2' width='4' height='12' fill='%23FFF' /%3E%3C/svg%3E");
  }
}

.warning {
  composes: icon;
  > a:after {
    @include au-icon("\37");
    color: $warning-color;
    /* dirty trick to fill the exclamation mark in white */
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' y='4' width='4' height='10' fill='%23FFF' /%3E%3C/svg%3E");
  }
}

.navlink:last-child {
  margin-left: unset !important;
  /* */
}

/* primary links selected styles */
.selected {
  color: #FFFFFF;
}

/* secondary links selected styles */
.navlink {
  .navlink {
    .selected {
        color: $AU_blue_shade_2 !important;
        cursor: default;
    }
  }
}
