@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$icon_size: 120px;
$icon_size_small: 90px;
$icon_text-color: #FFFFFF;
$icon_disabled-color: $alert;
$icon_enabled-color: $primary_btn;
$icon_stopped-color: $alert;
$icon_running-color: #1A678C;

.container {
  background-color: rgba(0, 133, 166, 0.1);
  border-radius: 60px;
  height: 120px;
  width: 120px;
}

.container_deep_sleep {
  background-color: #E49816;
}

.container_bound, .container_connected, .container_received_user_input_allow, .container_device_response_received {
  background-color: #5B8037;
}

.container_not_bound, .container_disconnected, .container_received_user_input_deny, .container_cancelled, .container_failure {
  background-color: $alert;
}

.container_expired {
  background-color: $AU_gray_shade_3;
}

.icon {
  &:before {
    color: $icon_text-color;
    font-size: $icon_size;
     /* dirty trick to fill the status icon in white */
    background-image: $global-icon-background-white;
  }
}

.running {
  composes: icon;
  &:before {
    @include au-icon("\e052");
    color: #4FAAd7;
  }
}

.invalid, .received_user_input_deny, .cancelled, .failure {
  composes: icon;
  &:before {
    @include au-icon("\27");
    color: $alert;
  }
}

.in_flight {
  composes: icon;
  &:before {
    @include au-icon("\e034");
    background: $icon-text-color;
    color: #4FAAd7;
    border-radius: 60px;
  }
}

.valid, .received_user_input_allow, .device_response_received, .success {
  composes: running;
  &:before {
    color: #5B8037;
    @include mq($from: tablet-ls) {
      background-color: #F3FCF3;
      background-image: none;
    }
  }
}

.connected {
  &:before {
    @include au-icon("\e053");
    font-size: $icon_size_small;
    background-color: #5B8037;
    color: $icon-text-color;
    position: relative;
    top: 15px;
    left: 15px;
    border-radius: 60px;
    @include mq($from: tablet) {
      color: #F3FCF3;
    }
  }
}

.bound {
  composes: connected;
  &:before {
    @include au-icon("\e033");
    background-image: none;
  }
}

.disconnected {
  composes: icon;
  &:before {
    @include au-icon("\e064");
    color: $alert;
    background-image: none;
    background-color: $icon-text-color;
    border-radius: 60px;
  }
}

.not_bound {
  composes: connected;
  &:before {
    @include au-icon("\e030");
    background-color: $alert;
  }
}

.deep_sleep {
  composes: icon;
  &:before {
    @include au-icon("\e055");
    font-size: $icon_size_small;
    color: $icon-text-color;
    background-color: #E49816;
    font-size: 100px;
    position: relative;
    top: 7px;
    left: 10px;
    border-radius: 60px;
    background-image: none;
    @include mq($from: tablet-ls) {
      color: #FFF7E7;
      top: 10px;
    }
  }
}

.expired {
  composes: icon;
  &:before {
    @include au-icon("\e02d");
    color: #6F6F6F;
  }
}

.inner_icon {
  &:before {
    position: absolute;
    margin-left: 25px;
    margin-top: 25px;
    font-size: 70px;
    color: $AU_blue_shade_0;
  }
}

.count {
  composes: inner_icon;
  &:before {
    @include au-icon("\e05f");
  }
}

.auth {
  composes: inner_icon;
  &:before {
    @include au-icon("\e065");
  }
}

.key {
  composes: inner_icon;
  &:before {
    @include au-icon("\e04e");
  }
}

.member_vehicle {
  composes: inner_icon;
  &:before {
    @include au-icon("\e062");
  }
}

.member_type {
  composes: inner_icon;
  &:before {
    @include au-icon("\e05f");
  }
}

.delivery {
  composes: inner_icon;
  &:before {
    @include au-icon("\e060");
  }
}

.device {
  composes: inner_icon;
  &:before {
    @include au-icon("\e063");
  }
}

.cloud {
  composes: inner_icon;
  &:before {
    @include au-icon("\e061");
  }
}

.flows {
  composes: inner_icon;
  &:before {
    @include au-icon("\e03d");
  }
}

.taps {
  composes: inner_icon;
  &:before {
    @include au-icon("\e03c");
  }
}

.unknown {
  composes: icon;
  &:before {
    @include au-icon("\e051");
    color: $AU_gray_shade_1;
  }
}