@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'src/css/globals.scss';

.title {
    @include font-size(18px, 26px);
    color: $AU_blue_shade_2;
    font-weight: 600;
}

.container {
    margin-top: 42px;
}

.table {
    margin-top: 32px;
    width: 536px;
}

.value {
    background-color: #FFFFFF;
}

.no_data {
    color: rgba(50, 70, 97, 0.3);
}