@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$input_width_desktop: 308px;
$btn_min_width: 95px;
$tags_height: 120px;
$form_height: 50px;

$input-color: $AU_blue_shade_2;
$button-color: $AU_blue_shade_1;
$tags_bg-color: #FFFFFF;
$tags_border-color: $AU_blue_gray_1;
$table_row_bg-color: #FFFFFF;
$table_edit_header_border-color: $AU_gray_shade_0;
$table_new_row_border-color: $AU_blue_shade_3;
$table_extra_row_bg-color: $AU_gray_shade_3;
$table_border-color: $AU_blue_gray_1;
$highlight_animation_color: #FAF8F0;


@keyframes user_added_animation {
  10.00%, 70.00% {
    background-color: $highlight_animation_color;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.form {
  flex: 1 0 auto;
  display: flex;

  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.table {
  border: 1px solid $AU_blue_gray_1;
  margin-top: 16px;
}

.row {
  &:nth-child(odd) {
    .cell {
      background-color: #F5F5F5;
    }
  }

  &.new {
    .cell {
      animation: user_added_animation 3s;
    }
  }
  &.remove {
    .cell {
      background-color: #FFF2F2;
    }
    .delete_btn {
      display: none;
    }
    .remove_message {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.remove_message {
  position: relative;
  display: none;
  @include font-size(16px, 27px);
}

.remove_label {
  right: 100%;
  white-space: nowrap;
  color: #A22330;
  margin-right: 24px;
}

.cell {
  padding-top: 0;
  padding-bottom: 0;
}

.user_input {
  flex: auto;
  max-width: 627px;

  @include mq($until: tablet) {
    margin-bottom: 10px;
  }
}

.user_input_label {
  padding-right: 15px;
  align-self: center;
  color: #6882A1;
}

.add_btn {
  margin-left: 16px;
}

.restore_btn {
  font-weight: normal;
  padding: 0;
  margin: 0 2px;
}

.delete_btn {
  border: none;
  background: none;
  outline: none;
  width: 42px;
  height: 42px;
  line-height: 42px;
  user-select: none;
  margin: 0 4px;
  cursor: pointer;
  font-size: 18px;

  &:after {
    @include au-icon("\69");
  }

  &,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    background-color: $hover;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.header_cell {
  composes: cell;
  border: none;
  height: 50px;
  line-height: 50px;
}

.actions {
  composes: cell;
  text-align: right;
  padding-right: 32px;
}