@use "sass:math";

@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

$table_tooltip_bg-color: #FAF8F0;
$table_tooltip_border-color: $AU_blue_shade_3 !default;

.container {
  padding: 10px 15px;
  background-color: $table_tooltip_bg-color;
  border: 1px solid $table_tooltip_border-color;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  line-height: math.div(20px, 13px);
  white-space: normal;
  max-height: 220px;
  width: 403px;
}

.above {
  position: absolute;
  bottom: 50px;
  min-height: 220px;
  > div:first-of-type {
    transform: rotate(180deg);
    top: 100%;
  }
}

.text {
  padding: 0 2px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 135px;
  text-transform: uppercase;
  white-space: normal;
  @include mq($from: desktop) {
    max-height: 120px;
  }
}

.searchbox {
  border: 1px solid $AU_gray_shade_0;
  width: 370px;
  margin-left: 0;
}

.tooltip {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid $table_tooltip_border-color;
  position: absolute;
  top: -15px;
  left: 175px;
  z-index: 20;
}

.tooltip:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 21px solid $table_tooltip_bg-color;
  position: absolute;
  top: 2px;
  left: -14px;
}
