@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  flex: 1;
  display: flex;
  line-height: 48px;
  @include mq(tablet, desktop) {
    margin-top: 8px;
  }
}

.label {
  text-align: right;
  min-width: 43px;
  @include mq(tablet, desktop) {
    color: $AU_blue_shade_4;
    text-align: right;
    margin-right: 10px;
    margin-left: 23px;
    display: flex;
    justify-content: flex-end;
    min-width: 80px;
  }
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 24px;
  @include mq($until: tablet) {
    margin-left: 10px;
  }
  @include mq($from: desktop) {
    padding-left: 16px;
    margin-right: unset;
  }
}

.input {
  flex: 1;
  display: flex;
  width: 100%;
  > div, > input {
    flex: 1 0 auto;
    width: auto !important;
  }
  > div {
    > div {
      /* toggle */
      @include mq(tablet, desktop) {
        border-left: 1px solid $AU_blue_gray_1;
      }
    }
  }
  @include mq(tablet, desktop) {
    margin-left: 0;
    margin-top: 5px;
    margin-right: 24px;
  }
}

.error_container {
  display: table;
}

.error {
  display: table-cell;
  margin-top: 8px;
  @include font-size(14px, 20px);
  padding-left: 8px;
  color: $alert;
  text-align: left;
}

.error_icon {
  display: table-cell;
  &:before {
    @include au-icon("\36");
    width: 14px;
    height: 14px;
    @include font-size (16px, 20px);
    color: $alert;
  }
}