@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  div[class^=au_drop_down__container] {
    z-index: 20;
  }
}

.value_text {
  align-self: start;
  margin-bottom: 20px;
  &:before{
    @include au-icon("\e02A");
    margin-right: 10px;
    color: $global-badge-running
  }
}