@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$bg-color: #FFFFFF;

.title {
  color: $AU_blue_shade_4;
  font-size: 16px;
  margin-right: 8px;
}

.description {
  font-size: 14px;
  color: $AU_blue_shade_1;
  margin-top: 15px;
  margin-bottom: 15px;

  &:before {
    @include au-icon("\e02a");
    font-size: 14px;
    color: #4FAAD7;
    margin-right: 8px;
  }
}

.date_time_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.date_time_fields > div:first-child {
  flex-grow: 10;
  width: 120px;
  min-width: 120px;
}

.time_period {
  flex-grow: 9;
  width: 90px;
  min-width: 90px;
  padding-left: 8px;

  @include mq($until: large-desktop) {
    width: 100px;
    min-width: 100px;
  }
}

.error {
  padding-top: 8px;
  font-size: 14px;
  color: #A22330;
  width: 100%;

  &:before {
    @include au-icon("\27");
    font-size: 14px;
    color: #A22330;
    margin-right: 10px;
  }
}

.main_title {
  display: flex;
  align-items: center;
}

.hint {
  @include font-size(14px, 20px);
  opacity: .6;
  color: $AU_blue_shade_4;
}

.sidebar_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sidebar_date {
  flex-grow: 10;
  width: 100%;
}

.sidebar_date > div > div > input {
  height: 48px;
  border: 1px solid #cfd5dc;
  padding: 0 16px;
  opacity: 0.6;
  @include font-size(14px, 20px);

  @include mq($until: desktop) {
    height: 60px;
  }
}

.selected_sidebar > div > div > input {
  opacity: 1;
  @include font-size(16px, 20px);
}

.invalid > div > div > input {
  background-color: #fff3f3;
  border-color: #a22330;
}

.calendar {
  margin-top: -1px;
  background: $bg-color;
  border: 1px solid;
}

.time_input {
  display: inline-flex;
  width: 100%;
  padding-top: 8px;
}

.hidden {
  display: none;
}

.calendar_container {
  display: block;
}