@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.sidebar_subviews {
  display: grid;
  grid-template-rows: min-content auto;
  width: 220px;
  min-width: 220px;
  margin-left: -32px;
  margin-right: 32px;
  border: 1px solid $AU_blue_gray_1;
  border-right: none;
  overflow-y: auto;
  background-color: $AU_gray_shade_3;

  &:after {
    content: "";
    border-right: 1px solid $AU_blue_gray_1;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @include mq($until: desktop){
    height: 100%;
    width: 200px;
    min-width: 200px;
    margin-left: 0;
    position: fixed;
    z-index: 1;
    left: 0;
  }
}



.sidebar_subviews_table > * {
  border-right: 1px solid $AU_blue_gray_1;
  padding-left: 35px;
  padding-right: 35px;
  height: 65px;
  display: flex;
  align-items: center;
  color: $AU_blue_shade_2;
}

.subview_header{
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  border-top: none;
  border-bottom: 1px solid $AU_blue_gray_1;
  border-left: none;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    width: 16px;
    height: 5px;
    margin-left: auto;
    background-color: $AU_blue_shade_2;
  }
}

.sidebar_subviews_table > a {
  font-size: 16px;

  &:visited {
    color: $AU_blue_shade_2;
  }
  &:hover {
    background-color: $AU_gray_shade_2;
  }
  &.selected {
    background-color: #FFFFFF;
    border-top: 1px solid $AU_blue_gray_1;
    border-bottom: 1px solid $AU_blue_gray_1;
    border-right: none;
    cursor: inherit;

    &:hover {
      color: $AU_blue_shade_2;
      background-color: #FFFFFF;
    }
  }
  &.section_end {
    border-bottom: 1px solid $AU_blue_gray_1;
  }
}

a.section_end + a.selected {
  border-top: 1px solid transparent;
}

.subview_header + a.selected {
  border-top: none;
}

.sidebar_subviews.closed {
  width: 45px;
  min-width: 45px;
  border-right: 1px solid $AU_blue_gray_1;
  padding: 0;
  cursor: pointer;
}

.close_icon {
  padding-top: 20px;
  padding-left: 10px;

  &:before {
    color: $AU_blue_shade_2;
    font-size: 16px;
    @include au-icon("\e066");
  }

  &:after {
    color: $secondary_btn;
    font-size: 16px;
    @include au-icon("\55");
  }
}
