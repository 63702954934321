@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

.container {
  margin: 6px 0;
}

.first_key {
  color: unset;
  &:before {
    content: "publishingDestinationAui: ";
    color: $AU_blue_shade_4;
  }
  &:after {
    content: "  ";
  }
}

.icon {
  color: unset;
  margin-right: 10px;
  &:before {
    content: "Destination:";
    color: $AU_blue_shade_4;
    margin-right: 32px;
  }
  &:after {
    position: absolute;
    left: 120px;
    top: 8px;
    color: unset;
    font-size: 20px;
    @include mq($from: desktop) {
      left: 114px;
    }
  }
}

.gcp {
  color: unset;
  margin-right: 10px;
  &:before {
    content: "Destination:";
    color: $AU_blue_shade_4;
    margin-right: 32px;
  }
  &:after {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 120px;
    font-size: unset;
    top: 8px;
    background: url('../../images/gcp.svg') no-repeat 50% / 20px 20px;
    content: "";
    @include mq($from: desktop) {
      left: 114px;
    }
  }
}

.kafka {
  composes: icon;
  color: $AU_blue_shade_2;
  &:after {
    @include au-icon("\e08a");
  }
}

.true {
  > span {
    color: #5C872B;
    text-transform: lowercase;
    &:before {
      font-size: 16px;
      color: #5C872B;
    }
  }
}

.false {
  > span {
    color: $alert;
    text-transform: lowercase;
    &:before {
      font-size: 16px;
    }
  }
}