@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$border-color: $AU_blue_gray_1;
$header_border-color: $AU_gray_shade_0;
$disabled-color: $AU_gray_shade_0;
$disabled_bg-color: #FFFFFF;
$disabled_border-color: $AU_gray_shade_0;
$error: #FFF2F2;

.container {
  margin-top: 16px;
}

.content {
  composes: content from "./entity_view.module.scss";
  > div:first-child { /* actions */
    position: absolute;
    right: 0;
    top: -46px;
    margin: 0;
  }
}

.bindings {
  border: 1px solid $border-color;
  margin-top: 18px;
  border-width: 1px 0;
  border-top-color: $header_border-color;
  border-collapse: collapse;
}

.cell {
  position: relative;
  background-color: $disabled_bg-color !important;
}

.header_row {
  border-top: 1px solid $header_border-color;
  border-bottom: 1px solid $header_border-color;
}

.header_row .cell {
  border-color: $header_border-color;
  line-height: 47px;
}

.row {
  .cell {
    padding: 17px 24px 18px;
    border: 1px solid $border-color;
  }
  &.new .cell {
    padding-top: 10px;
    padding-bottom: 11px;
  }
}

.hint {
  opacity: .65;
  margin-left: 8px;
  font-family: $global-font-base;
  font-weight: normal;
  @include font-size(14px, 18px);
}

.type {
  composes: cell;
  width: 300px;
}

.serial {
  composes: cell;
  flex: 1;
}

.action {
  composes: cell;
  width: 200px;
  text-align: center;
  margin-top: 14px;
}

.row .action {
  padding: 0;
}

.icon_btn {
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  user-select: none;
  text-align: center;
  padding: 0;
  margin-top: 9px;

  &, &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: $hover;
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.delete {
  composes: icon_btn;
  cursor: pointer;
  font-size: 16px;
  &:after {
    @include au-icon("\69");
  }
}

.actions {
  &.fixed {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 500px;
  }
}

.confirmation, .deleting {
  height: 100%;
  display: flex;
  background-image: linear-gradient(to left, $error 100%, rgba($hover, 0) 0%);
  @include mq($from: tablet) {
    background-image: linear-gradient(to left, $error 90%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 00%, rgba($hover, 0) 10%);
  }  align-items: center;
  justify-content: flex-end;
  margin-right: 0;
  button {
    font-size: 16px;
  }
}

.proceed {
  min-width: 125px;
}

.cancel {
  margin: 0 32px 0 24px;
  width: 62px;
  padding: 0;
}

.deleting_btn {
  min-width: 125px;
  margin-right: 118px
}

.msg {
  margin-right: 24px;
}

.buttons {
  text-align: center;
  margin-top: 3px;
}

.btn {
  width: 93px;
}

.save_btn {
  composes: btn;
  font-size: 16px;
}

.cancel_btn {
  composes: btn;
  width: auto;
  height: 24px;
  min-height: 24px;
  margin-left: 22px;
  padding: 0;
  &:disabled {
    background-color: $disabled_bg-color;
    color: $disabled-color;
    opacity: 0.65;
  }
}

.no_bindings {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  color: inherit;
}
