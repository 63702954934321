///* ========================================================================
//   #NO-USER-SELECT
//   ======================================================================== */

// Mixin to disable text selection of the element and sub-elements
@mixin no-user-select() {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
