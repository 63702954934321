@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$border_color: $AU_blue_gray_1;

$header-color: $AU_blue_shade_2;
$header-bg_color: $AU_gray_shade_3;
$content-bg_color: #FFFFFF;

$counter-color: #FFFFFF;
$counter-bg_color: #7F198D;

$width-desktop: 334px;
$width-desktop-l: 375px;
$width-desktop-xl: 472px;

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-left: 32px;
  border: 1px solid $border_color;
  border-bottom: none;
  z-index: 3;
  @include mq($until: large-desktop) {
    width: $width-desktop;
  }
  @include mq(large-desktop, x-large-desktop) {
    width: $width-desktop-l;
  }

  @include mq($from: x-large-desktop) {
    width: $width-desktop-xl;
  }
}

.header {
  height: 65px;
  display: flex;
  align-items: center;
  color: $header-color;
  background: $header-bg_color;
  padding: 0 25px 0 32px;
  border-bottom: 1px solid $border_color;
  margin-bottom: 0;
}

.label {
  @include font-size(14px, 18px);
  font-family: $global-font-sans;
  font-weight: 600;

  &:after {
    display: inline-block;
    content: attr(data-count);
    height: 20px;
    @include font-size(14px, 20px);
    padding: 0px 6px;
    background-color: $counter-bg_color;
    color: $counter-color;
    margin-left: 10px;
  }
}

.reset {
  margin-left: 24px;
}

.close {
  cursor: pointer;
  padding: 7px;
  margin-left: auto;
  &:after {
    content: "";
    display: block;
    width: 16px;
    height: 5px;
    background-color: $AU_blue_shade_2;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow-y: auto;
  height: 0;
  background-color: $content-bg_color;
  &::-webkit-scrollbar {
    display: none;
  }
}

.visible  {
  .filter_header {
    position: sticky;
    top: 0;
    z-index: 10; // so filters are not visible when scrolling behind selected filters
    background: #FFFFFF;
  }
}
