@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$text-white: #FFFFFF;
$success-green: $global-badge-success-green;
$invalid_color: $alert;

.badge {
  display: inline-block;
  line-height: 24px;
  vertical-align: top;
  padding: 0 10px;
  color: $text-white;
  > span {
    display: flex;
    align-items: center;
  }
}

.in_progress {
  background-color: $global-badge-running;
}

.success, .received_user_input_allow, .device_response_received {
  background-color: $success-green;
}

.complete {
  background-color: #ffffff;
  color: $success-green;
}

.failure, .received_user_input_deny {
  background-color: $alert;
}

.expired {
  background-color: $AU_gray_shade_0;
}

.cancelled {
  background-color: $AU_blue_shade_0;
}

.stopped {
  background: $alert;
}

.paused {
  background: $AU_blue_shade_1;
}

.invalid {
  background: $global-badge-invalid;
  color: $alert;
  border: 1px solid $alert;
  > div {
    padding-top: 1px;
    > span {
      &:before {
        top: 2px;
      }
    }
  }
}

.command_definition_draft,
.replay {
  background: $global-badge-starting;
}

.command_definition_enabled {
  background: $global-badge-success-green;
}

.command_definition_disabled {
  background: $AU_gray_shade_0;
}

.command_definition_valid {
  background: $text-white;
  color: $global-badge-success-green;
  border: 1px solid $global-badge-success-green;
  border-color: $global-badge-success-green;
  > div {
    padding-top: 1px;
    > span {
      &:before {
        top: 2px;
      }
    }
  }
}

.command_definition_invalid {
  composes: invalid;
}

.running {
  background: $global-badge-running;
}

.in_flight {
  background: $global-badge-running;
}

.starting {
  background: $global-badge-starting;
}

.unknown {
  background: $text-white;
  border: 1px $AU_gray_shade_1 solid;
  color: $AU_gray_shade_0;
  > div {
    padding-top: 2px;
  }
}

.incomplete,  .partial_success, .partial_failure {
  background: #FFF7E7;
  color: #AD6E02;
  border: 1px solid #E49816;
  > div {
    padding-top: 1px;
    > span {
      &:before {
        top: 2px;
      }
    }
  }
}
