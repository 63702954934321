@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin-top: 16px;
  max-width: 100vw;
}

.panel {
  background-color: $AU_gray_shade_3;
  @include mq($until: tablet-ls) {
    padding: 8px 32px 24px;
  }
  @include mq($from: tablet-ls) {
    padding: 24px 32px;
  }
}

.buttons {
  @include mq($from: tablet) {
    button:first-child {
      min-width: 235px;
    }
  }
}

.helper_text {
  @include font-size(14px, 20px);
}
