@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

.common {
  display: none;
}

.mobile {
  composes: common;
  @include mq(mobile, tablet){
    display: block;
  }
}

.tablet {
  composes: common;
  @include mq(tablet, tablet-ls){
    display: block;
  }
}

.tablet_ls {
  composes: common;
  @include mq(tablet-ls, desktop){
    display: block;
  }
}

.desktop {
  composes: common;
  @include mq($from: desktop){
    display: block;
  }
}
