@import 'src/css/globals.scss';

.entity_link_list {
  display: flex;
  height: 100%;
}

.entity_link_list_sub{
  display: inline-block;
  line-height: 24px;
  min-width: 0;
}

.entity_link_list_sub > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.plus {
  display: block;
  line-height: 1;
  font-size: 15px;
  color: $AU_gray_shade_0;
  margin-top: 8px;
}

.popup_container {
  position: absolute;
  z-index: 1;
  padding-top: 12px;
}

.popup {
  border: 1px solid $AU_blue_shade_1;
  background-color: $hover;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
}

.popup::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 30px;
  transform: rotate(45deg) skew(18deg, 18deg);
  height: 12px;
  width: 12px;
  border: inherit;
  border-bottom: none;
  border-right: none;
  background: inherit;
}

.popup_list {
  max-height: 190px;
  overflow: auto;
  padding: 10px 20px;
  line-height: 1.75;
}

.popup_list > * {
  display: block;
}

item {
  display: block;
  text-overflow: ellipsis;
}
