@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

.group_detail {
  > .group_name, .group_id, .group_link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  > .group_name {
    @include font-size(16px, 16px);
    @include mq(535px, tablet) {
      margin-top: 10px;
    }
    @include mq(tablet, desktop) {
      margin-top: 17px;
    }
    @include mq($from: desktop){
      margin-top: 12px;
    }
  }
  > .group_link, .group_id {
    @include font-size(16px, 16px);
  }

}
