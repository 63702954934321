@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

// NOTE keep in sync with fieldset.scss [$input-width]
$max-width: 550px;

$error-color: $alert;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  div[title] {
    max-width: 300px;
  }
}

.dropdown {
  margin-bottom: 12px;
  max-width: 550px;
  position: relative;
}

.selection {
  display: inline-flex;
}

.invalid > div {
  border-color: $alert !important;
  background-color: $error !important;
  input {
    background-color: $error;
  }
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: $error-color;
  padding-left: 20px;
  margin-top: 10px;
  &:before {
    @include au-icon("\27");
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
  }
}

.note {
  position: relative;
  padding-left: 10px;
  @include font-size(14px, 20px);
  margin-top: 6px;
  &:before {
    content: "*";
    position: absolute;
    left: 0;
  }
}

.warning {
  line-height: 24px;
}
