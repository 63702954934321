.flex {
  display: flex;
  flex-direction: column;
  > .row {
    flex: 1;
    display: flex;
  }
}

.table {
  display: table;
  width: 100%;
  > .row {
    display: table-row;
    > div {
      display: table-cell;
    }
  }
}

.row {
  &:nth-child(n+2) {
    margin-top: 5px;
  }
}
