@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'src/css/tools/tools.au-icon';

$width: 60px;
$height: 60px;

.container {
  width: $width;
  height: $height;
  display: flex;
  background-color: $AU_gray_shade_3;
  border: 1px solid $AU_blue_gray_1;
}

.icon {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $AU_blue_shade_0;
  padding-top: 16px;
  &:before {
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    content: attr(data-caption);
    font-family: $global-font-sans;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    color: #324661;
  }
  &:after {
    display: block;
    font-size: 16px;
  }
  &:hover {
    color: $link;
  }
}

.search_icon {
  composes: icon;
  &:after {
    @include au-icon("\72");
  }
}

.close_icon {
  composes: icon;
  &:after {
    @include au-icon("\65");
  }
}

.search_container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 24px;
  height: $height;
  right: calc(#{$width} + 24px);
  top: 8px;
  background-color: $AU_gray_shade_3;
  border: 1px solid $AU_blue_gray_1;
}

.search_box {
  width: 500px;
  display: flex;
  align-items: center;
}

.search_container_icon {
  width: 35px;
  height: 48px;
  display: flex;
  position: relative;
  right: 35px;
}

.selection {
  > button {
    display: none;
  }
}

.search_container_search_icon {
  composes: icon;
  &:after {
    @include au-icon("\72");
  }
}

.search_container_close_icon {
  composes: icon;
  &:after {
    @include au-icon("\65");
  }
}

.hide_toggle {
  display: none;
}

.dropdown {
  @include mq($from: tablet-ls) {
    width: 560px;
  }
}
