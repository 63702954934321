@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

.container {
  //
}

.icon {
  margin-left: 8px;
}

.container .tooltip {
  max-width: 400px;
  padding: 24px;
  @include font-size(16px, 27px);
}

.tooltip .title {
  font-weight: 600;
  margin-bottom: 18px;
  @include font-size(16px, 27px);
}