@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$header-border_color: $AU_blue_gray_1;
$icon_size: 20px;
$icon-color: $AU_blue_shade_0;
$filter-btn-color: rgba(127, 25, 141, .11);
$filter_bg-color: $AU_gray_shade_3;
$filter_border-color: $AU_blue_gray_1;

.container {
  display: flex;
  flex-direction: column;
  &:not(.show) {
    display: none;
  }
  &:not(.open) {
    border-bottom: 1px solid $filter_border-color;
    .filter {
      display: none;
    }
  }
}

.header {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 0 32px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
}

.toggle {
  height: 58px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  background-color: #FFFFFF;
}

.filter {
  background-color: $filter_bg-color;
  border-bottom: 1px solid $filter_border-color;
  /* selectable list */
}

.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(#{$icon-size} - 3px);
    width: $icon_size;
    height: $icon-size;
    color: $icon-color;
  }
  &:after {
    @include au-icon("\45");
    color: $secondary_btn;
    position: relative;
    font-size: $icon-size;
    padding-left: 6px;
  }
}

.open .icon:after {
  @include au-icon("\56");
}

.open {
  + .visible .filter_header + div {
    border-bottom: 0;
  }
}

.unknown {
  padding-left: 16px;
}

.selection {
  width: unset;
  margin: 0 32px 8px 32px;;
  @include font-size(16px, 27px);
  display: flex;
  align-items: center;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.counter {
  margin-left: 4px;
}

.close {
  display: flex;
  cursor: pointer;
  &:before {
    @include au-icon("\65");
    font-size: 10px;
    padding: 5px;
    margin-left: 4px;
    font-weight: 600 !important;
    color: $AU_blue_shade_2;
  }
}

.loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
}

.pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $filter-btn-color;
  border-radius: 16px;
  padding: 3px 18px;
}

.title {
  width: 100%;
}

.padding {
  padding-bottom: 22px;
}

.button {
  margin-left: auto;
  margin-right: 35px;
}

.selection_container {
  max-height: 400px;
  overflow: auto;
}

.dd_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.open_header {
  border-bottom: 1px solid $filter_border-color;
}