@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

.icon {
  &:before {
    margin-right: 8px;
  }
}

.failure {
  composes: icon;
  &:before {
    @include au-icon("\36");
    color: $alert;
  }
}

.success {
  composes: icon;
  top: 70px;
  @include mq(tablet, tablet-ls) {
    top: 75px;
  }
  &:before {
    @include au-icon("\7d");
    color: $primary_btn;
  }
}
