@use "sass:math";

@import 'src/css/globals.scss';
@import 'src/css/tools/tools.no-user-select';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';

$foreground-color: $AU_blue_shade_0;
$title-color: #FFFFFF;

$button-color: #FFFFFF;
$button_caption-color: $secondary_btn;
$button_bg-color: $primary_btn;

$logo_width: 80px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  top: 0;
  width: 100%;
  height: 54px;
  background-color: $foreground-color;
}

.adjusted {
  left: $logo_width;
  padding-right: $logo_width;
  width: calc(100% - #{$logo_width});
}

.title {
  left: auto;
  right: 16px;
  color: $title-color;
  font-family: $global-font-sans;
  font-weight: 600;
  text-transform: capitalize;
  z-index: 5;
  max-height: 54px;
  overflow: hidden;

  line-height: 54px;
  text-align: center;

  @include mq($from: tablet){
    right: 24px;
  }

  &.wide {
    max-width: calc(100vw - 125px);
  }
  &:not(.wide) {
    max-width: calc(100vw - 225px);
  }
}

.button {
  @include no-user-select();
  display: flex;
  align-items: center;
  position: absolute;
  left: 24px;
  height: 54px;
  font-family: $global-font-sans;
  font-weight: bold;
  font-size: math.div(14px, 15px) * 1em;
  color: $button_caption-color;
  text-transform: capitalize;

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include mq($from: tablet){
    display: none;
  }

  > span {
    line-height: 1;
  }
}

.create {
  composes: button;

  &:before {
    @include au-icon("\74");
    color: $button-color;
    width: 34px; height: 32px;
    border-radius: 3px;
    background-color: $button_bg-color;
  }

  .caption {
    @include mq($until: tablet){
      display: none;
    }
    @include mq($from: tablet){
      margin-left: 10px;
    }
  }
}

.back {
  composes: button;

  &:before {
    @include au-icon("\5a");
    width: 12px; height: 20px;
    font-size: math.div(33px, 14px) * 1em;
  }

  .caption {
    margin-left: 8px;
  }
}
