@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

.container {
  width: 533px;
}


.header {
  @include font-size(20px, 28px);
  font-weight: 600;
  padding-left: unset;

  &:before {
    @include au-icon('\e050');
    margin-right: 10px;
  }
}

.message {
  @include font-size(16px, 24px);
  margin-bottom: 25px;
  max-height: 72px;
}

.list_break{
  border-bottom: 1px solid $AU_blue_gray_1;
}

.date_time {
  padding-top: 25px;

  & > div > div {
    &:first-child {
      flex-grow: 1;
      width: 120px;
      min-width: 120px;
    }
    &:nth-child(2) {
      flex-grow: 0;
      width: 100px;
      min-width: 100px;
    }
    &:nth-child(3) {
      flex-grow: 0;
      width: 100px;
      min-width: 100px;
    }
    &:nth-child(4) {
      flex-grow: 1;
      width: 120px;
      min-width: 120px;
    }
  }
}

.data_resume_limit {
  margin-top: 10px;

  &:before {
    @include au-icon('\e02a');
    color: $global-badge-running;
    margin-right: 5px;
  }
}
