@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px;

  @include mq($until: desktop) {
    overflow-y: auto;
    padding: 0 24px 32px 0;
    margin-left: 230px;
    &.open {
      margin-left: 80px;
    }
  }
}

.subview_open {
  @include mq($until: desktop) {
    margin-left: 230px;
  }
}

.subview_closed {
  @include mq($until: desktop) {
    margin-left: 75px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px 0;
}

.content {
  flex: 1;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding-top: 36px;
  @include font-size(22px, 30px);

  @include mq($until: desktop){
    margin: 24px 25px 18px 32px;
  }
  @include mq($from: desktop){
    height: 84px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  @include font-size(16px, 16px);
  margin-left: 32px;
  > *:nth-child(n+2) {
    margin-left: 32px;
  }
  ~ .section {
    margin-top: 8px;
  }
}

.button {
  vertical-align: top;
  min-width: 120px;
  padding-left: 33px;
  padding-right: 33px;

  &:nth-child(n + 2) {
    margin-left: 16px;
  }
}

.list {
  composes: button;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}

.edit {
  composes: button;
}

.delete {
  composes: button;
}

.details {
  composes: button;
}

.multi_action {
  height: 48px !important;
  @include mq($until: tablet) {
    width: 48px !important;
  }
  > span {
    height: 48px !important;
    line-height: 48px !important;
    /* caret */
    &:nth-child(1) {
      width: 48px !important;
    }
  }
  > div {
    @include mq($from: desktop) {
      min-width: 145px;
    }
  }
}

@for $i from 1 through 10 {
  .row#{$i} {
    height: 50px * $i;
    @include mq(tablet, tablet-ls) {
      height: 60px * $i;
    }
  }
}
.row {
  composes: row1
}

.column {
  > :first-child {
    white-space: nowrap;
  }
  padding: 0 20px;
  border: 1px solid $AU_gray_shade_1;
}

.fieldname {
  composes: column;
  color: $AU_blue_shade_4;
  background-color: $AU_gray_shade_3;
  min-width: 140px;
  vertical-align: top;
  > span {
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 13px;
  }
}

.fieldvalue {
  composes: column;
  width: 100%;
}

.label {
  composes: fieldname;
  border: 0;
  @include mq(tablet, desktop) {
    display: flex;
    padding-top: 15px;
  }
}

.filters_row {
  composes: row;
  background-color: $AU_gray_shade_3;
  border: 1px solid $AU_gray_shade_1;
  > .fieldvalue {
    padding: 0;
  }
}

.separator {
  border-bottom: 1px solid $AU_blue_gray_1;
  padding: 16px 20px 0 0;
}

.pre_line {
  white-space: pre-line;
  padding: 12px 20px;
}

.view_json {
  padding-left: 0;
  padding-right: 0;
  font-weight: normal;
  &:disabled {
    color: $AU_gray_shade_0;
    background: none;
    background-color: none;
  }
}

.json_button {
  color: #1188AA;
  padding: 0 20px;
}

.spacer {
  height: 32px;
}

.table_topper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -20px;
  margin-bottom: 8px;
  @include font-size(16px, 28px);
  cursor: pointer;
}

.json_row {
  background-color: #ffffff;
  cursor: pointer;
}

.hint {
  font-family: $global-font-base;
  font-weight: normal;
  @include font-size(14px, 24px);
  color: $AU_blue_shade_2;
  opacity: 0.3;
}

.log_copy_btn {
  width: unset !important;
  padding: 0 16px;
  top: 0 !important;
}

.placeholder {
  @include font-size(14px, 20px);
  opacity: .3;
  color: $AU_blue_shade_1;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  line-height: 48px;
  background-color: $AU_gray_shade_3;
  img {
    max-width: 32px;
    max-height: 32px;
  }
}

.error {
  composes: message;
  background-color: $error;
  &:before {
    color: $alert;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    @include au-icon("\27");
  }
}

.link {
  margin-bottom: 10px;
}

.content_flex {
  display: flex;
  height: 100%;
  margin-top: 48px;
}

.table {
  flex-grow: 1;
}

.bottom_gap_24 {
  margin-bottom: 24px;
}

.top_gap_50 {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.no_margin {
  margin: 0;
}

.no_margin_top {
  margin-top: 0;
}

.hide_before {
  &:before {
    display: none;
  }
}
