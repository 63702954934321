@import 'globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$value-color: $AU_blue_shade_2;

.control {
  flex: 3;
  display: flex;
  > span {
    padding-left: 1px;
  }
}

.max_exp_control {
  composes: control;
  flex-direction: column;
}

.inputs {
  display: flex;
}

.input {
  width: 100px;
}

.command_type_toggle {
  width: 0;
  height: 0;
  &:after {
    content: none;
  }
}

.command_version_control {
  display: table-cell;
}

.command_version_input {
  composes: input;
  margin-right: 16px;
}

.seconds_input {
  width: 130px;
}

.version_control {
  display: flex;
  flex-direction: row;
}

.version_input {
  margin-right: 10px;
}

.hint {
  @include font-size(14px, 20px);
  margin-bottom:10px;
  &:before {
    @include au-icon("\e02a");
    color: #1C96CF;
    margin-right: 10px;
  }
}

.caption {
  @include font-size(14px, 20px);
  color: $AU_blue_shade_2;
  opacity: .5;
}