@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';

.last_ftcp_message_container {
  margin: 0 -20px;
}

.icon {
  &:before {
    margin-right: 8px;
  }
}

.failure {
  composes: icon;
  &:before {
    @include au-icon("\36");
    color: $alert;
  }
}

.success {
  composes: icon;
  position: relative;
  &:before {
    @include au-icon("\7d");
    color: $primary_btn;
  }
}

.loading_row {
  border-left: 1px solid $AU_gray_shade_3;
}

.loading {
  padding: 37px 20px;
  position: absolute;
  border: 1px solid $AU_gray_shade_3;
  color: $AU_blue_shade_2;
  background-color: $AU_gray_shade_3;
  width: 100%;
  height: 98px;
  text-align: center;
}

.spinner {
  height: 33px;
  width: 33px;
  color: $AU_blue_shade_0;
  margin: auto 21px auto 0px;

  >img {
    margin-right: 20px;
  }
}

.error_row {
  border-left: 1px solid $error;
}

.error_message {
  border: 1px solid $error;
  background-color: $error;
  vertical-align: top;
  width: 100%;
	height: 98px;
  text-align: center;
  position: absolute;
  > span {
    display: inline-block;
    padding-top: 37px;
  }
  &:before {
    @include au-icon("\36");
    font-size: 1.5em;
    margin-right: 8px;
    color: $alert;
    vertical-align: middle;
  }

}

.stage_header {
  width: 160px;
}

.time_header {
  width: 260px;
}

.result_header {
  width: 120px;
}

.detail_row {
  padding: 1px;
}

.no_col_padding {
  padding: 0;
}
