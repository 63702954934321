@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$info_bg-color: #EEF7FF;
$info_border-color: #1C96CF;

.container {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  & > * {
    height: calc(100vh - 235px);
  }
}

.loader {
  display: flex;
  align-items: center;
  margin: 64px 0;
  height: 32px;
}

.links_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .links {
    display: flex;
    align-items: center;
  }
  .actions {
    display: flex;
    font-weight: 400;
    .action {
      font-weight: normal;
      font-family: $global-font-base;
      padding: 0;
      font-size: 16px;
      min-height: 24px;
      height: 24px;
    }
  }
}

.separator {
  display: flex;
  margin: 0 20px;
  width: 1px;
  height: 18px;
  background-color: $AU_blue_shade_3;
}
.content_wrapper {
  width: 100%;
}

.content {
  flex: 1 1 auto;
  overflow-y: scroll;
  height: calc(100vh - 268px);
}

.manage_policies {
  position: absolute;
  top: 0;
  right: 0;
}

.buttons {
  display: flex;
}

.group {
  z-index: 4;
}

.panel {
  position: relative;
  margin-bottom: 32px;
  /* title */
  > div:first-child {
    background-color: $AU_gray_shade_3;
    font-weight: 600;
    text-transform: unset;
    padding-left: 32px;
    line-height: 24px;
    height: 72px;
    width: 100%;
    display: flex;
    font-family: $global-font-base;
    position: sticky;
    top: 0;
    z-index: 1;
    &:hover, &:focus {
      z-index: 9;
    }
    .actions {
      z-index: 10;
      &:hover, &:focus {
        z-index: 99;
      }
      @include mq($until: tablet-ls){
        > div {
          display: none;
        }
      }
    }
    > div:first-child {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      &:before {
        right: unset;
        width: 16px;
        height: 16px;
        margin-right: 16px;
        @include au-icon("\21");
        font-size: 16px;
        color: $AU_blue_shade_1;
      }
    }
    &:after {
      content: none;
    }
  }
  > div:last-child {
    position: static;
    padding-left: 32px;
  }
}

.collapsed {
  padding-bottom: 16px;
  max-height: 72px;
  overflow: visible;
  /* title */
  > div:first-child {
    > div:first-child:before {
      @include au-icon("\e07b");
    }
    > div:first-child:after {
      transform: unset;
    }
  }
  > div:last-child {
    display: none;
  }
  .manage_policies {
    display: none;
  }
}

.info {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: $info_bg-color;
  border-left-color: $info_border-color;
  border-left-style: solid;
  border-left-width: 5px;
  margin-top: -8px;
  position: relative;
  &:before {
    @include au-icon("\e02a");
    font-size: 18px;
    color: $info_bg-color;
    margin-right: 10px;
  }
}
