@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/settings/_settings.colors';

@mixin compose-icon($prefix, $name, $icon, $paused: false, $stopped: false, $running: false, $replay: false, $inferred: false) {
  .#{$prefix}-#{$name} {
    &:before {
      @if $paused { color: $global-paused-icon; }
      @if $stopped { color: $alert; }
      @if $running { color: $global-badge-running; }
      @if $replay { color: #B67B02; }
      @if $inferred { color: #CFD5DC; }
      @include au-icon($icon);
    }
  }
}

@include compose-icon("processor", "RUNNING", "\e070", false, false, true);
@include compose-icon("processor", "PAUSED", "\e071", true);
@include compose-icon("processor", "STOPPED", "\e072", false, true);
@include compose-icon("processor", "INFERRED", "\e070", false, false, false, false, true);
@include compose-icon("tap", "RUNNING", "\e073", false, false, true);
@include compose-icon("tap", "PAUSED", "\e06c", true);
@include compose-icon("tap", "STOPPED", "\e06b", false, true);
@include compose-icon("tap", "REPLAY", "\e087", false, false, false, true);
@include compose-icon("tap", "INFERRED", "\e073", false, false, false, false, true);
@include compose-icon("fork", "RUNNING", "\e06d", false, false, true);
@include compose-icon("fork", "PAUSED", "\e06f", true);
@include compose-icon("fork", "STOPPED", "\e06e", false, true);
@include compose-icon("fork", "REPLAY", "\e088", false, false, false, true);
@include compose-icon("fork", "INFERRED", "\e06d", false, false, false, false, true);
@include compose-icon("direct-route", "INFERRED", "\e087", false, false, false, false, true);

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include font-size(16px, 24px);
  &:before {
    margin-right: 8px;
  }
}

.container:not(:last-child) {
  margin-bottom: 4px;
}

.direct-route-INFERRED {
  &:before {
    color: #FFFF;
    background-color: #CFD5DC;
  }
}