html {
  overflow-y: auto; // undo vertical scroll forced by au-core
}

.page {
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
