@import 'src/css/globals.scss';

.list {
  list-style: none;
  margin: 0 -20px;
  padding: 0;
}

.item {
  padding: 12px 20px;
  + .item {
    border-top: 1px solid $AU_gray_shade_1;
  }
}
