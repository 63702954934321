@import 'src/css/globals.scss';

.container {
  margin: 0 -20px;
}

.table {
  border-collapse: collapse;
  border: 0;
}

.row .cell {
  padding-top: 12px;
  padding-bottom: 12px;
}

.row {
  &:nth-child(even) {
    .cell {
      background-color: $AU_gray_shade_3;
    }
  }
}

.resource_header {
  background-color: #E7EAED !important;
  padding: 10px 24px;
  border-bottom: 1px solid #D0D0D0;
}

.resource_odd {
  background-color: #ffffff !important;
  padding: 10px 24px;
}

.resource_even {
  background-color: #F5F5F5 !important;
  padding: 10px 24px;
}

.key {
  composes: cell;
  width: 316px;
}

.value {
  composes: cell;
}

.header .cell {
  border-bottom: 1px solid $AU_gray_shade_0;
  background-color: $AU_gray_shade_2;
}

.width_fill {
  margin-left: -20px;
  width: calc(100% + 40px);
}
