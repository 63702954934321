@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$unbind_icon-color: #000000;

.unbind_dialog {
  &:before {
    @include au-icon("\e030");
    line-height: 32px;
    color: $unbind_icon-color;
  }
  .header {
    padding-left: 46px;
  }
}

.icon {
  &:before {
    margin-right: 8px;
  }
}

.unbound {
  composes: icon;
  &:before {
    @include au-icon("\36");
    color: $alert;
  }
}

.bound {
  position: relative;
  composes: icon;
  &:before {
    @include au-icon("\7d");
    color: $primary_btn;
  }
}


%overlay {
  text-align: center;
  color: $AU_blue_shade_2;
}

.dcs_loading {
  @extend %overlay;
  background-color: $AU_gray_shade_3;
}

.dcs_error {
  @extend %overlay;
  background-color: #fff2f2;
}

.dcs_error_msg {
  &:before {
    @include au-icon("\36");
    font-size: 1.5em;
    margin-right: 8px;
    color: $alert;
    vertical-align: middle;
  }
}
