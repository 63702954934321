@import 'src/css/globals.scss';

.container {
  /* */
}

.form {
  form {
    > div:first-child {
      max-width: calc(100vw - 64px); // desktop/tablet
    }
  }
}

.tabs {
  padding-top: 0;
  margin-bottom: 24px;
}
