@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$overlay_bg-color: #FFFFFF;
$overlay_border-color: $AU_blue_shade_4;

$calendar_btn-color: #FFFFFF;
$calendar_btn_bg-color: $secondary_btn;

$arrow-color: $secondary_btn;

$fieldset_label_text-color: $AU_blue_shade_4;
$fieldset_value_text-color: $AU_blue_shade_3;

.container {
  display: flex;

  @include mq($until: tablet){
    flex-direction: column;
  }
  @include mq($from: desktop){
    align-items: baseline;
  }
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dateWrapper {
  composes: wrapper;
}

.timeWrapper {
  composes: wrapper;
  @include mq($until: tablet){
    margin-top: 24px;
  }
  @include mq(tablet, desktop){
    margin-left: 60px;
    border: 0;
  }
  @include mq($until: desktop){
    position: relative;
    input[type='text'] {
      background-color: #FFFFFF;
    }
    .inline {
      align-items: baseline;
    }
  }
  @include mq($from: desktop){
    margin-left: 10px;
  }
}

.datepicker {
  @include mq($until: tablet) {
    input[type='date']:after {
      padding-right: 5px;
    }
  }
  @include mq(tablet, tablet-ls) {
    input[type='date']:after {
      left: 230px;
    }
  }
  @include mq(tablet-ls, desktop) {
    input[type='date']:after {
      left: 220px;
    }
  }
  @include mq(tablet, desktop) {
    input[type='date']:after {
      position: absolute;
      width: 50px;
    }
  }
  @include mq($until: desktop) {
    input {
      word-spacing: 8px;
    }
    input[type='date'] {   
      display: none;
      /* Remove First */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Then Style */
      border: 1px solid $AU_blue_gray_1;
      padding: 10px;
      line-height: 20px;
      text-align: left;
      background: transparent;
      outline: none;
      border-radius: 0;
      border-color: none;
    }:after {
      @include au-icon("\45");
      padding: 15px 0 15px 15px;
      border-left: 1px solid $AU_blue_gray_1;
      position: relative;
      cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
    }
  }
  @include mq($from: desktop) {
    display: none;
  }
}

.timepicker {
  @include mq($until: tablet) {
    input[type='time'] {
      border: none;
    }
  }
  @include mq(tablet, desktop) {
    input[type='time'] {
      border: 1px solid $AU_blue_gray_1;
    }
  }
  @include mq($until: desktop) {
    flex: 1;
    position: inherit;
    input[type='time'] {   
      /* Remove First */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  
      /* Then Style */
      padding: 10px;
      line-height: 20px;
      text-align: left;
      background: transparent;
      outline: none;
      border-radius: 0;
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
      cursor: pointer;
    }
    input {
      word-spacing: 8px;
      + div { /* error message */
        @include mq($until: tablet){
          margin-right: -43px;
        }
        @include mq(tablet, tablet-ls){
          margin-left: calc(-100vw / 2 + 20px);
        }
        @include mq(tablet-ls, desktop){
          margin-left: calc(-100vw / 2 + 148px);
        }
      }
    }
  }
  @include mq($from: tablet) {
    display: none;
  }
}

.daypicker {
  flex: 1;
  display: flex;

  + .btn {
    position: absolute;
    right: 1px;
    top: 1px;
  }
  @include mq($until: tablet) {
    position: relative;
    :global {
      .DayPicker {
        background-color: $overlay_bg-color;
        border: 1px solid $AU_blue_shade_4;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        position: absolute;
        z-index: 20;
        top: 46px;
        left: -249px;
      }
    }
  }
  @include mq($from: desktop) {
    :global {
      .DayPicker-Day--selected {
        color: #FFFFFF!important;
        background-color: $AU_blue_shade_3!important;
      }
    }
  }
}

.input {
  flex: 1;
  input {
    @include mq($until: desktop) {
      background-color: $overlay_bg-color !important;
      border: 1px solid $AU_blue_gray_1;
    }
    width: 100%;
    padding: 5px 33px 5px 10px;
    @include mq($from: desktop) {
      border-radius: 0 2px 2px 0;
    }
    + div { /* error message */
      margin-right: -243px;
      max-width: 550px;
    }
  }
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: $overlay_bg-color;
  border: 1px solid $AU_blue_shade_4;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  margin-top: -1px;
  z-index: 3;
}

.btn {
  cursor: pointer;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq($until: desktop) {
    position: absolute;
    background: #FFFF;
    border-left: 1px solid $AU_blue_gray_1;
    &:before {
    @include au-icon("\45");
    padding: 15px;
    }
  }
  @include mq($from: desktop) {
    background-color: $calendar_btn_bg-color;
    &:before {
      @include au-icon("\28");
      color: $calendar_btn-color;
      width: 20px;
      height: 22px;
      font-size: 22px;
    }
  }
}

.label {
  @include mq($from: desktop) {
    flex: 1;
  }
  display: flex;
  line-height: 1.5;
  white-space: nowrap;
  padding-bottom: 3px;
  color: $fieldset_label_text-color;
}

.inline {
  display: flex;
  align-items: center;
  @include mq($from: desktop){
    position: relative;
  }
}

.inline_date {
  justify-content: space-between;
  background-color: #FFFF;
}

.short {
  width: 52px;
}

.colon {
  padding: 0 6px;
}

.tz {
  @include mq($until: tablet) {
    display: none;
  }
  padding: 0 6px;
  @include font-size($global-font-size-desktop, $global-line-height-desktop);
}

.colon {
  padding: 0 6px;
}

.hours, .minutes, .colon {
  @include mq($until: tablet) {
    display: none;
  }
}