@import 'src/css/globals.scss';

.btn_container {
  margin: auto;
  display: table;
  margin-top: 24px;
}

.container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 30px;
}

.date_range {
  padding: 0;
  padding-bottom: 30px;
}

.date_time {
  padding: 30px 30px 0;
}

.end_section {
  padding-top: 30px;
}