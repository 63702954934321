
@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$error: #FFF2F2;
$success-green: #5C872B;
$success_text-green: #5C872B;
$success_bg-color: $AU_gray_shade_3;
$success_border-color: $AU_gray_shade_3;

.container {
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    &:before {
      position: absolute;
      top: 14px;
    }
  }

  .valid_banner {
    background-color: $success_bg-color;
    border-left-color: $success_border-color;
    &:before {
      color: $success_text-green;
    }
  }
}

.banner_create {
  > div {
    &:before {
      top: 14px;
      @include mq($from: desktop) {
        top: 26px;
      }
    }
  }
}

.banner_info {
  > div {
    &:before {
      @include au-icon("\e02a");
      top: 26px;
    }
  }
}

.banner_alert, .banner_success {
  > div {
    padding-left: 48px;
    &:before {
      font-size: 18px;
      top: 16px;
      left: 20px;
    }
    > ul {
      padding-left: 4px;
      @include mq($from: desktop) {
        padding-top: 10px;
      }
      li {
        &:before {
          margin-top: 5px;
          margin-right: 4px;
        }
      }
    }
  }
}

.message {
  color: $AU_blue_shade_2;
}

.list {
  margin: 0;
  @include font-size(14px, 24px);
}

.record {
  margin-left: 12px;
}

.icon {
  list-style-type: none;
  margin-left: 0;
  &:before {
    font-size: 16px;
    position: relative;
    margin-right: 16px;
  }
}

.success {
  composes: icon;
  color: $success_text-green;
  &:before {
    @include au-icon("\2a");
    color: $success-green;
  }
}

.warning {
  composes: icon;
  padding-left: 32px;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    border: 1px solid $AU_blue_shade_3;
    background-color: $AU_blue_shade_3;
    border-radius: 50px;
    width: 4px;
    height: 4px;
    left: 7px;
    top: 10px;
  }
}

.manage_filters {
  margin-top: 16px;
}