@import 'src/css/globals.scss';

// shard between topology components
.container {
  // the container has to have it's own styles so it could be matched to an element
  display: block;
  :global(marker#arrowhead path) {
    fill: $AU_blue_gray_1;
  }
}

.node {
  font-size: 12px;
  fill: $AU_blue_shade_1;
  font-family: $global-font-base;
}

.node_under {
  @extend .node;
  stroke: white;
  stroke-width: 3px;
}

.edge {
  stroke: $AU_blue_gray_1;
  stroke-width: 2px;
  fill: none;
  marker-end: url('#arrowhead');
}

.clickable_node {
  cursor: pointer;
}

.inferred_node {
  cursor: not-allowed;
}

.node {
  opacity: 0.75;
}
